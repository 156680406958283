import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import TrustedSlider from '../Components/TrustedSlider';
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from 'react-router-dom';

export default function Designer() {
  return (
    <>
      <Helmet>
        <title>Hire Pre-Vetted Designers | Junior to Senior Levels</title>
        <meta name="title" content="Hire Pre-Vetted Designers | Junior to Senior Levels" />
        <meta
          name="description"
          content="Are you looking for skilled remote designers? Sourcedesk offers pre-vetted talent in flexible time zones at competitive rates. Start your project today!"
        />{' '}
        {/*
          <!-- Canonical & hrefLang --> */}
        <link rel="canonical" href="https://www.sourcedesk.io/designers" />
        <link rel="alternate" hrefLang="x-default" href="https://www.sourcedesk.io/designers" />
        <link rel="alternate" hrefLang="en-US" href="https://www.sourcedesk.io/designers" />{' '}
        {/*
          <!-- Twitter Card meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@sourcedeskgl" />
        <meta name="twitter:title" content="Hire Pre-Vetted Designers | Junior to Senior Levels" />
        <meta
          name="twitter:description"
          content="Are you looking for skilled remote designers? Sourcedesk offers pre-vetted talent in flexible time zones at competitive rates. Start your project today!"
        />
        <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />{' '}
        {/*
          <!-- Facebook Open Graph tags --> */}
        <meta property="og:url" content="https://www.sourcedesk.io/designers" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Hire Pre-Vetted Designers | Junior to Senior Levels" />
        <meta
          property="og:description"
          content="Are you looking for skilled remote designers? Sourcedesk offers pre-vetted talent in flexible time zones at competitive rates. Start your project today!"
        />
        <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />{' '}
        {/*
          <!-- Additional Open Graph tags for better customization --> */}
        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1260" />
        <meta property="og:image:alt" content="SourceDesk Global" />
        <meta property="og:locale" content="en_US" />{' '}
        {/*
          <!-- Required meta tags --> */}{' '}
        {/*
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw " />
          <meta name="robots" content="index,follow" /> */}{' '}
        <script type="application/ld+json">
          {`{
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "@id": "https://www.sourcedesk.io/#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.sourcedesk.io/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Hire Pre-Vetted Designers | Junior to Senior Levels",
                    "item": "https://www.sourcedesk.io/designers"
                  }
                ]
              }`}
        </script>
        <script type="application/ld+json">
          {`{
                "@context": "http://schema.org",
                "@type": "Review",
                "itemReviewed": {
                  "@type": "Product",
                  "name": "Graphic Design",
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.5",
                    "bestRating": "5",
                    "worstRating": "1",
                    "ratingCount": "100" // Example: assuming 100 ratings contributed to the aggregate rating
                  }
                },
                "author": {
                  "@type": "Person",
                  "name": "SourceDesk"
                },
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "4.5",
                  "bestRating": "5",
                  "worstRating": "1"
                },
                "reviewBody": "Review Body"
              }`}
        </script>
      </Helmet>
      <InnerBanner
        InnBanner="url('images/banner-graphic-design.jpg')"
        bgRepeat="no-repeat"
        bgColor="#f9fcfc"
        BnrRightImg="images/graphic-design-banner-right.png"
        TitleInn={['Hire Expert Remote ', <span>Designers</span>, ' with Sourcedesk']}
        ParaInn="We connect you with full-time remote designers who work in flexible time zones to meet your specific needs. With their years of experience, our designers are experts at collaboration and timely delivery of their work."
        priCTALink="/schedule-a-call"
        priCTATitle="Hire a Designer"
        secCTALink={''}
        secCTATitle={''}
      />
      <TrustedSlider defaultTitle={'Trusted By'} />
      <section className="full_width fix_padding" style={{ background: '#fafbfd' }}>
        <div className="container">
          <div className="text_controller">
            <h2>
              {' '}
              Each <span>Designer</span> Is Selected Based on the Following Parameters{' '}
            </h2>
            <hr />
          </div>
          <div className="row Human_img_bottom">
            <div className="col-12 col-md-7 col-sm-7 col-xl-7 Hmn_img_bt_left">
              <ul>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">Technically Sound</div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    {' '}
                    Problem-Solving <br /> Skills{' '}
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">Cultural Fit</div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    {' '}
                    Outstanding <br /> Communication Skills{' '}
                  </div>
                </li>
                <li>
                  <div className="Hmn_bt_left_border"></div>
                  <div className="Hmn_bt_left_text">
                    {' '}
                    Time Management <br /> Skills{' '}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-5 col-sm-5 col-xl-5 Hmn_img_bt_right">
              {' '}
              <img src="images/graphic-design-inverted-bird.png" alt="Digital Marketing Service" />{' '}
            </div>
            <div className="cmn_btn_grp">
              {' '}
              <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
                {' '}
                Hire a Designer{' '}
              </NavLink>{' '}
              <NavLink to="https://www.sourcedesk.io/apply-as-talent" className="cmn_btn_stroke">
                {' '}
                Apply Here{' '}
              </NavLink>{' '}
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2 className="text-controller"> Why You Should Hire Dedicated Designers Through Sourcedesk </h2>
            <hr />
          </div>
          <div className="row mt-40 align-items-center">
            <div className="col-md-6 col-sm-6">
              <div className="">
                {' '}
                <img src="images/graphic-design-triangle.png" alt="Hire ai ml developers" />{' '}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="stppng_accordian" id="accordionExample">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> Top Talent Selection</Accordion.Header>
                    <Accordion.Body>
                      {' '}
                      We select designers through rigorous vetting of their technical skills. This process helps us provide you with the best talent to meet your needs.{' '}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Cost-Effective Solutions </Accordion.Header>
                    <Accordion.Body>
                      {' '}
                      Hiring designers through Sourcedesk helps you save overhead costs without sacrificing quality. You'll have access to talented designers at competitive rates.{' '}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Global Prowess </Accordion.Header>
                    <Accordion.Body>
                      {' '}
                      Our offshore designers come from diverse cultures and backgrounds. This brings with them a new perspective on projects and ensures that the outcome resonates with a wide
                      audience.{' '}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Flexible Management</Accordion.Header>
                    <Accordion.Body> Whether you need designers at an hourly rate or want them to be paid monthly, our flexible models will fit your business needs. </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width pricing_sec digital_mark_pricing fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Employ Dedicated Designers at Competitive Price </h2>
            <hr className="hr_black_bg" />
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
              <div className="prcng_sec_item">
                <div className="prcng_sec_item_icon">
                  <img src="images/volume-control1.png" alt="" />
                </div>
                <div className="prcng_sec_item_text">
                  <h3>Junior Level</h3>
                  <h4>0-3 Years of Experience</h4>
                  <h2>
                    <sup>$</sup>5 - <sup>$</sup>20
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
              <div className="prcng_sec_item">
                <div className="prcng_sec_item_icon">
                  <img src="images/volume-control2.png" alt="" />
                </div>
                <div className="prcng_sec_item_text">
                  <h3>Mid Level </h3>
                  <h4>3-7 Years of Experience</h4>
                  <h2>
                    <sup>$</sup>15 - <sup>$</sup>25
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
              <div className="prcng_sec_item">
                <div className="prcng_sec_item_icon">
                  <img src="images/volume-control3.png" alt="" />
                </div>
                <div className="prcng_sec_item_text">
                  <h3>Senior Level</h3>
                  <h4>7+ Years of Experience</h4>
                  <h2>
                    <sup>$</sup>20 - <sup>$</sup>40
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="price_content">Save up to 66% cost as compared to candidates in the USA and Canada.</div>
        </div>
      </section>
      <section className="full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Our Pre-Vetted <span>Designers</span> for You
            </h2>
            <hr />
          </div>
          <div className="row slider-profiles" id="cmn_slider_btn">
            <div className="col-12 col-md-4 col-sm-4 col-xl-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  {' '}
                  <img src="images/Debasish_Ghosh.png" alt="IMG" />
                  <h2>Debasish Ghosh</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                    <li>UI/UX Design</li>
                    <li>Social Media Creatives</li>
                    <li>3D Modeling</li>
                    <li>Icon Design</li>
                    <li>Video Editing</li>
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>9+ Years</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>Hooghly, India</h3>
                </div>{' '}
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  {' '}
                  <img src="images/chintu_smile.png" alt="IMG" />
                  <h2>Chintu Adhikary</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                    <li>Sketching</li>
                    <li>Painting</li>
                    <li>Graphic Design</li>
                    <li>Character Illustration</li>
                    <li>Social Media Creatives</li>
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>14+ Years</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>Kolkata, India</h3>
                </div>{' '}
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="-1">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  {' '}
                  <img src="images/Arpita_Maity.png" alt="IMG" />
                  <h2>Arpita Maity</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                    <li>2D Illustration</li>
                    <li>Drawing</li>
                    <li>Digital Sketch</li>
                    <li>Creative Painting</li>
                    <li>Designs</li>
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>6+ Years</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>Kolkata, India</h3>
                </div>{' '}
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="-1">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
              Secure Top Talent
            </NavLink>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding section-col-3" style={{ background: '#f9fcfc' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2> How the Vetting Process at Sourcedesk Works </h2>
            <hr />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>AI-Based Screening</h3>
                <p>We use advanced AI to pick the right designer for you.</p>
              </div>
              <div className="content-box">
                <h3>Technical Round</h3>
                <p>Selected candidates take online tests to assess their technical skills. </p>
              </div>
            </div>
            <div className="col-xl-4 d-none d-xl-flex align-items-center justify-content-center col-img">
              <img src="images/graphic-design-label-img.png" alt="" />
            </div>
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>Requirement Sharing</h3>
                <p>We ask you to provide your requirements so we can find the designer you need. </p>
              </div>
              <div className="content-box">
                <h3>Hiring and Onboarding</h3>
                <p>After you choose your designer, Sourcedesk will handle their contract and onboarding on your behalf. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cmn_btn_grp cmn_btn_grp_center">
          <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
            Invest in Your Success
          </NavLink>
        </div>
      </section>
      <section className="full_width vrtul_asstnt_devloper acc_finance_hire digital_mark_hire fix_padding">
        <div className="container">
          <div className="three_tab_top text_controller">
            <h2>
              Platform for Dedicated <span>Designers</span>
            </h2>
            <hr />
          </div>
          <div className="sdg_devlopers_technologies">
            <div>
              <h2>Designers</h2>
              <ul>
                <li>
                  <h3>UI/UX Developers </h3>
                </li>
                <li>
                  <h3>Sketch Artists</h3>
                </li>
                <li>
                  <h3>Graphic Designer</h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
