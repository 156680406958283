import { NavLink } from "react-router-dom";
import DevProfiles from "../Components/DevProfiles";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
import { Helmet } from "react-helmet";

export default function VirtualAssistant() {
  return <>
  <Helmet>
  <title>Hand-Picked Pre-Vetted Remote Virtual Assistants | Sourcedesk</title>
<meta name="description" content="Elevate productivity with Sourcedesk's hand-picked virtual assistants. Pre-vetted for success in remote collaboration. Your secret to efficient support." />
<meta name="title" content="Hand-Picked Pre-Vetted Remote Virtual Assistants | Sourcedesk" />
    {/* <!-- Canonical & hrefLang --> */}
    <link rel="canonical" href="https://www.sourcedesk.io/virtual-assistant" />
    <link rel="alternate" hrefLang="x-default" href="https://www.sourcedesk.io/virtual-assistant" />
    <link rel="alternate" hrefLang="en-US" href="https://www.sourcedesk.io/virtual-assistant" />

    {/* <!-- Twitter Card meta tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@sourcedeskgl" />
    <meta name="twitter:title" content="Hand-Picked Pre-Vetted Remote Virtual Assistants | Sourcedesk" />
    <meta name="twitter:description" content="Elevate productivity with Sourcedesk's hand-picked virtual assistants. Pre-vetted for success in remote collaboration. Your secret to efficient support." />
    <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />
    {/* <!-- Facebook Open Graph tags --> */}
    <meta property="og:url" content="https://www.sourcedesk.io/virtual-assistant" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Hand-Picked Pre-Vetted Remote Virtual Assistants | Sourcedesk" />
    <meta property="og:description" content="Elevate productivity with Sourcedesk's hand-picked virtual assistants. Pre-vetted for success in remote collaboration. Your secret to efficient support." />
    <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />
    {/* <!-- Additional Open Graph tags for better customization --> */}
    <meta property="og:image:width" content="2400" />
    <meta property="og:image:height" content="1260" />
    <meta property="og:image:alt" content="SourceDesk Global" />
    <meta property="og:locale" content="en_US" />

{/* <!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 

{/* <!-- Head End --> */}
<script type="application/ld+json">
{`{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "@id": "https://www.sourcedesk.io/#breadcrumb",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Hire Our Genius Minds",
      "item": "javascript:void(0)"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Virtual Assistant",
      "item": "https://www.sourcedesk.io/virtual-assistant"
    }
  ]
}`}
</script>
<script type="application/ld+json">
{`{
  "@context": "http://schema.org",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "Virtual Assistant",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    }
  },
  "author": {
    "@type": "Person",
    "name": "SourceDesk"
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4.5",
    "bestRating": "5",
    "worstRating": "1"
  },
  "reviewBody": "Review Body"
}`}
</script>
  </Helmet>
    <InnerBanner
        InnBanner="url('images/vrtal_asstnt_banner.png')"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/vrtal_asstnt_banner_grphcs.png"
        TitleInn={["Enhance Business Efficiency with Exceptional ",<span>Virtual Assistant</span>," Services"]}
        ParaInn="Unlock the Power of Highly-Skilled Remote Assistants for Optimal Efficiency"
        priCTALink="/schedule-a-call" priCTATitle="Discover the Power of Virtual Assistance" secCTALink={""} secCTATitle={""}
      />
    <TrustedSlider defaultTitle={"Trusted By"} />
    <section className="full_width SDGvsOTHERS apply_job_position acc_solution role_fix fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2>The Diverse Expertise of Our <span> Virtual Assistant</span> Candidates </h2>
                <hr/>               
            </div>

            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Role</th>
                            <th scope="col">Skills</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th style={{width:"40%"}}>Administrative Assistant</th>
                            <td style={{width:"60%"}}>Our skilled Administrative Assistants excel in calendar and email management, travel booking, data entry, and organized file management for smooth business operations.</td>
                        </tr>

                        <tr>
                            <th style={{width:"40%"}}>Personal Assistant</th>
                            <td style={{width:"60%"}}>Our dedicated Personal Assistants specialize in schedule management, research, email coordination, event planning, and travel coordination to enhance your productivity.</td>
                        </tr>

                        <tr>
                            <th style={{width:"40%"}}>Social Media Specialist</th>
                            <td style={{width:"50%"}}>Our skilled Social Media Specialists excel in management, scheduling, content creation, and reporting to establish and maintain your brand's online presence effectively.</td>
                        </tr>

                        <tr>
                            <th style={{width:"40%"}}>Content Writer</th>
                            <td style={{width:"60%"}}>Our experienced Content Writers create compelling website copy, engaging blog posts, informative articles, persuasive social media content, and precise product descriptions to resonate with your target audience.</td>
                        </tr>

                        <tr>
                            <th style={{width:"40%"}}>Customer Service Representative</th>
                            <td style={{width:"60%"}}>Our Customer Service Representatives provide exceptional support through email, phone, and chat, resolving issues and ensuring seamless order processing for satisfied customers. </td>
                        </tr>


                        <tr>
                            <th style={{width:"40%"}}>Real Estate Assistant</th>
                            <td style={{width:"60%"}}>Our skilled Real Estate Assistants specialize in property listing strategies, optimizing listings, and generating qualified leads to maximize sales opportunities.</td>
                        </tr>

                        <tr>
                            <th style={{width:"40%"}}>E-Commerce Assistant</th>
                            <td style={{width:"60%"}}>Our E-Commerce Assistants excel at efficient product listing, order management, and providing a seamless customer experience. </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <DevProfiles varientClass={"devlopers_profile vrtul_asstnt_profile acc_finance_profile"} heading={["Explore Our ",<span>Expert Profiles</span>]} priCTALink={"/schedule-a-call"} priCTATitle={"Discover Skilled Professionals"} />
    <section className="full_width pricing_sec fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2>Unlock Cost Efficiency with <span> Sourcedesk Global’s </span> Virtual Assistants </h2>  
                <hr className="hr_black_bg"/>                           
            </div>
            <div className="row">
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon"><img src="images/volume-control1.png" alt=""/></div>
                        <div className="prcng_sec_item_text">
                            <h3>Junior Level</h3>
                            <h4>0-3 years of experience</h4>
                            <h2><sup>$</sup>5 - <sup>$</sup>10</h2>
                            <span>Per Hour</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon"><img src="images/volume-control2.png" alt=""/></div>
                        <div className="prcng_sec_item_text">
                            <h3>Mid Level</h3>
                            <h4>3-7 years of experience</h4>
                            <h2><sup>$</sup>10 - <sup>$</sup>15</h2>
                            <span>Per Hour</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon"><img src="images/volume-control3.png" alt=""/></div>
                        <div className="prcng_sec_item_text">
                            <h3>Senior Level</h3>
                            <h4>7+ years of experience</h4>
                            <h2><sup>$</sup>15 - <sup>$</sup>25</h2>
                            <span>Per Hour</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pricing_note"><h4><span> ***</span>Experience Remarkable Cost Savings of up to 66% Compared to USA and Canada</h4></div>
        </div>
    </section>
    <section className="full_width Hiring_Genius vrtul_asstnt_staff remote_dvloper fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2>Unlock the Power of <br/> <span> Virtual Assistants</span> for Your Business</h2>              
                <hr/>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-sm-4 col-xl-4 col-lg-6 Hrng_Gns_left">
                    <ul>
                        <li> 
                            <h2>Specify Your Skill Requirements</h2>
                            <p> Discuss your skill requirements with us, and we will schedule a call to understand your needs.</p>
                        </li>
                        <li> 
                            <h2>Select Handpicked Talent</h2>
                            <p>Our AI algorithm and HR experts curate pre-vetted candidates for your personalized dashboard.</p>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4  Hrng_Gns_filter"><img src="images/how_hire_img.png" alt="Hire Virtual Assistants"/></div>
                <div className="col-12 col-md-6 col-sm-4 col-xl-4 col-lg-6 Hrng_Gns_right">
                    <ul>
                        <li> 
                            <h2>Schedule Interviews</h2>
                            <p>Our dedicated Account Manager assists you in scheduling interviews with top talents of your choice. </p>
                        </li>
                        <li> 
                            <h2>Start Your Risk-Free Trial</h2>
                            <p>Get started on your project with confidence by taking advantage of our two-week risk-free trial period.</p>
                        </li>                                                          
                    </ul>
                </div>
            </div>
            <div className="cmn_btn_grp cmn_btn_grp_center">
                <NavLink to="/schedule-a-call" className="cmn_btn_fill">Boost Your Efficiency with Our Skilled VAs</NavLink>
            </div>
        </div>
    </section>
    <section className="full_width sdg_devlopers vrtul_asstnt_devloper three_tab fix_padding ">
        <div className="container">
            <div className="three_tab_top text_controller">
                <h2>Maximize Business Efficiency with Specialized  <span>Virtual Assistants</span></h2>  
                <hr/>
            </div>
            <div className="sdg_devlopers_technologies ">
                <div>
                    <h2>Virtual Assistant</h2>
                    <ul>
                        <li>Personal VA</li>
                        <li>Real Estate VA</li>
                        <li>Data Entry Expert</li>
                        <li>E-Commerce VA</li>
                    </ul>                
                </div>
            </div>

                
        </div>    
    </section>
  </>
}