import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import TrustedSlider from '../Components/TrustedSlider';
import Accordion from 'react-bootstrap/Accordion';
import { NavLink } from 'react-router-dom';

const FAQsData = [
  {
    faq: 'How much does it cost to hire designers from Sourcedesk?',
    ans: 'Designer rates vary based on experience. We offer junior designers $15 per hour and experienced designers up to $40 per hour. We’re also open to discussing costs based on your specific project needs.',
  },
  {
    faq: 'How do you check the qualifications of a designer?',
    ans: 'You can simply ask our designers technical questions based on the design tools you want the project to incorporate. The more accurate their responses, the more confident you can be in their qualifications.',
  },
  {
    faq: 'Will the designers be available in my time zone?',
    ans: 'Yes. Our dedicated resources will be available to work in any time zone. You can discuss the timeframe with the designers to work accordingly.',
  },
  {
    faq: 'How fast can you hire a designer for me?',
    ans: 'We will shortlist the candidate for you in 3 to 5 days. Then, you can directly interact with the designer and make your selection. ',
  },
  {
    faq: 'How do I hire a designer from Sourcedesk?',
    ans: 'You simply fill out the query form on our website and schedule a call with our representative. Share your requirements and we will find the best candidate for you. ',
  },
  {
    faq: 'How do I communicate with your designers?',
    ans: 'Emails or messaging tools are some of the ways you can communicate directly with your designer. Similarly, Sourcedesk has internal tools that allow you to interact with them. ',
  },
];
export default function GraphicsDesigner() {
  return (
    <>
      <Helmet>
        <title>Hire Top-Tier Graphics Designers for Your Project | Sourcedesk</title>
        <meta name="title" content="Hire Top-Tier Graphics Designers for Your Project | Sourcedesk" />
        <meta
          name="description"
          content="Looking for top Graphics Designers? Use Sourcedesk's thorough vetting process, which carefully assesses each candidate’s technical and soft skills, to match your needs."
        />
        {/*
      <!-- Canonical & hrefLang --> */}
        <link rel="canonical" href="https://www.sourcedesk.io/designers" />
        <link rel="alternate" hrefLang="x-default" href="https://www.sourcedesk.io/designers" />
        <link rel="alternate" hrefLang="en-US" href="https://www.sourcedesk.io/designers" />
        {/*
      <!-- Twitter Card meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@sourcedeskgl" />
        <meta name="twitter:title" content="Hire Top-Tier Graphics Designers for Your Project | Sourcedesk" />
        <meta
          name="twitter:description"
          content="Looking for top Graphics Designers? Use Sourcedesk's thorough vetting process, which carefully assesses each candidate’s technical and soft skills, to match your needs."
        />
        <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />
        {/*
      <!-- Facebook Open Graph tags --> */}
        <meta property="og:url" content="https://www.sourcedesk.io/designers" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Hire Top-Tier Graphics Designers for Your Project | Sourcedesk" />
        <meta
          property="og:description"
          content="Looking for top Graphics Designers? Use Sourcedesk's thorough vetting process, which carefully assesses each candidate’s technical and soft skills, to match your needs."
        />
        <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />

        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1260" />
        <meta property="og:image:alt" content="SourceDesk Global" />
        <meta property="og:locale" content="en_US" />

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "@id": "https://www.sourcedesk.io/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.sourcedesk.io/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Hire Top-Tier Graphics Designers for Your Project | Sourcedesk",
                "item": "https://www.sourcedesk.io/designers"
              }
            ]
          }`}
        </script>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Graphics Designer",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.5",
                "bestRating": "5",
                "worstRating": "1",
                "ratingCount": "100" // Example: assuming 100 ratings contributed to the aggregate rating
              }
            },
            "author": {
              "@type": "Person",
              "name": "SourceDesk"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Review Body"
          }`}
        </script>
      </Helmet>
      <InnerBanner
        InnBanner="url('images/graphics-designer-banner.png')"
        bgRepeat="no-repeat"
        bgColor="#f9fcfc"
        BnrRightImg="images/graphics-designer-banner-right.png"
        TitleInn={['Hire a Pre-Vetted Graphics Designers Within 3-5 Days']}
        ParaInn="Are you in need of a dedicated Graphics Designers? Sourcedesk has the perfect solution. We provide thoroughly vetted Graphics Designers ready to bring your projects to life."
        priCTALink="/schedule-a-call"
        priCTATitle="Hire a Graphics Designers"
        secCTALink={''}
        secCTATitle={''}
      />
      <TrustedSlider defaultTitle={'Trusted By'} />
      <section className="full_width fix_padding section-why-sdg-is-best">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2 className="text-controller"> Find Top-Tier Graphics Designers at Sourcedesk </h2>
            <hr />
            <p>
              At Sourcedesk, we offer expert offshore Graphics Designers to drive your project's success. Our rigorous vetting process guarantees that only the most qualified professionals are
              selected to match your organization’s requirements.
            </p>
          </div>
          <div className="row mt-40 align-items-center">
            <div className="col-md-6 col-sm-6">
              <div className="">
                <img src="images/graphics-designer-accordion.png" alt="Hire ai ml developers" />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="stppng_accordian" id="accordionExample">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Expert Graphics Designers</Accordion.Header>
                    <Accordion.Body>
                      Our Graphics Designers are dedicated professionals who work with passion and excellence. They integrate with your teams effortlessly and quickly align with your business goals.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Find Graphics Designers from Around the Globe</Accordion.Header>
                    <Accordion.Body>
                      Our team of skilled sketch designers is available worldwide, allowing us to accommodate your schedule regardless of your time zone. We connect you with professionals who can
                      adapt to your preferred working hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Successful Completion of Project</Accordion.Header>
                    <Accordion.Body>
                      Equipped with the latest technologies and tools, our remote candidates ensure the successful completion of your projects. With a 100% success rate, our experts consistently
                      deliver high-quality work on time.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Thorough Vetting Process</Accordion.Header>
                    <Accordion.Body>
                      Before providing you with Graphics Designers, we rigorously vet candidates to check their qualifications. We also carefully evaluate their technical and soft skills before hiring
                      them for your projects.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Some of Our Dedicated Designers</h2>
            <hr />
          </div>
          <div className="row slider-profiles" id="cmn_slider_btn">
            <div className="col-12 col-md-4 col-sm-4 col-xl-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src="images/Debasish_Ghosh.png" alt="IMG" />
                  <h2>Debasish Ghosh</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                    <li>UI/UX Design</li>
                    <li>Social Media Creatives</li>
                    <li>3D Modeling</li>
                    <li>Icon Design</li>
                    <li>Video Editing</li>
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>9+ Years</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>Hooghly, India</h3>
                </div>
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="0">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src="images/chintu_smile.png" alt="IMG" />
                  <h2>Chintu Adhikary</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                    <li>Sketching</li>
                    <li>Painting</li>
                    <li>Graphic Design</li>
                    <li>Character Illustration</li>
                    <li>Social Media Creatives</li>
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>14+ Years</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>Kolkata, India</h3>
                </div>
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="-1">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4">
              <div className="dvlprs_prfle_row">
                <div className="profile_author">
                  <img src="images/Arpita_Maity.png" alt="IMG" />
                  <h2>Arpita Maity</h2>
                </div>
                <div className="main_skill">
                  <h4>MAIN SKILLS</h4>
                  <ul>
                    <li>2D Illustration</li>
                    <li>Drawing</li>
                    <li>Digital Sketch</li>
                    <li>Creative Painting</li>
                    <li>Designs</li>
                  </ul>
                </div>
                <div>
                  <h4>YEARS OF EXPERIENCE</h4>
                  <h3>6+ Years</h3>
                </div>
                <div>
                  <h4>LOCATION</h4>
                  <h3>Kolkata, India</h3>
                </div>
                <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke" tabindex="-1">
                  Hire Similar Profiles
                </NavLink>
              </div>
            </div>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
              Build Your Team Now
            </NavLink>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding section-technical-expertise" style={{ background: '#f9fcfc' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>Tools and Platforms Used by Our Designers</h2>
            <hr />
          </div>
          <ul className="uniq_infrmtn_down">
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Graphic Design, Logo & Branding</h3>
              </div>
              <div className="uniq_infrmtn_right">Figma, Adobe XD, Sketch, InVision Studio, Photoshop, Illustrator, Affinity Designer, Canva, CoralDRAW</div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>UI/UX</h3>
              </div>
              <div className="uniq_infrmtn_right">Figma, Adobe XD, Sketch, InVision Studio, Photoshop, Affinity Designer</div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Wireframes</h3>
              </div>
              <div className="uniq_infrmtn_right">Figma, Adobe XD, Sketch, InVision Studio, Balsamic</div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Prototyping</h3>
              </div>
              <div className="uniq_infrmtn_right">Figma, Adobe XD, Sketch, InVision Studio, Balsamic</div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Sketch</h3>
              </div>
              <div className="uniq_infrmtn_right">Illustrator, Procreate, Fresco, CoralDRAW</div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Video Editing</h3>
              </div>
              <div className="uniq_infrmtn_right">Premier Pro, After Effects, Fillmora, Final Cut Pro</div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Motion Graphics</h3>
              </div>
              <div className="uniq_infrmtn_right">After Effects, Filmora</div>
            </li>
          </ul>
          <div className="cmn_btn_grp cmn_btn_grp_center cmn_btn_exchange cmn_btn_wide">
            <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_stroke">
              Recruit Top Graphics Designers
            </NavLink>
            <NavLink to="https://www.sourcedesk.io/hire-development-team" className="cmn_btn_fill">
              Build Your Design Team
            </NavLink>
          </div>
        </div>
      </section>
      <section className="full_width bg_dark_blue fix_padding" style={{ background: '#1b212d' }}>
        <div className="container">
          <div className="text_controller">
            <h2>What Makes Sourcedesk Different?</h2>
            <hr />
            <p>We're more than just a virtual assistant. Here are some key points that set us apart from our competitors.</p>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 react_ft_cnt">
              <ul>
                <li>
                  <h3>AI-Aided Applicant Screening</h3>
                  <p>Sourcedesk uses advanced AI tools to simplify the screening process. Our expert managers further evaluate applicants for their skill qualifications.</p>
                </li>
                <li>
                  <h3>Affordable Rates</h3>
                  <p>
                    By choosing our services, you can reduce your recruitment and salary costs by two-thirds. We provide flexible pricing options, including monthly and hourly rates, for accessing
                    top-tier talent.
                  </p>
                </li>
                <li>
                  <h3>Access to Global Resources</h3>
                  <p>We connect you with Graphics Designers from different regions around the world. Their global experience adds a valuable dimension, enhancing creativity in every task.</p>
                </li>
                <li>
                  <h3>IT Support & Office Accessibility</h3>
                  <p>
                    We offer workspace for your chosen candidate, ensuring they can work comfortably. Our comprehensive IT support offers technical assistance, making it easy for your experts to work.
                  </p>
                </li>
                <li>
                  <h3>Flexible Contracts</h3>
                  <p>Whether you want to hire a remote Graphics Designers for a short or long tenure, we can hire professionals accordingly. Enjoy flexible contracts as per your needs.</p>
                </li>
                <li>
                  <h3>High Retention Rate</h3>
                  <p>Sourcedesk offers exceptional perks to candidates such as medical benefits, team lunches, corporate events and work flexibility to retain them longer. </p>
                </li>
                <li>
                  <h3>Dedicated Account Manager</h3>
                  <p>We provide a dedicated account manager to ensure your Graphics Designers meets your requirements. They oversee the process to guarantee smooth and efficient work.</p>
                </li>
                <li>
                  <h3>Easy Hiring Process</h3>
                  <p>Share your requirements with us, and we'll match you with the perfect professional artist. Our quick turnaround time ensures we select the right candidate within 3-5 days.</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="react_ft_img">
                <img src="images/graphics-designer-points.png" alt="react_ft_img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding section-col-3" style={{ background: '#fff' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>How Talent Acquisition Works at Sourcedesk</h2>
            <hr />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>Share Your Requirements</h3>
                <p>Simply share your requirements, and we’ll match you with the best Graphics Designers for your needs.</p>
              </div>
              <div className="content-box">
                <h3>Technical Assessment</h3>
                <p>Candidates undergo online tests to evaluate their technical skills.</p>
              </div>
            </div>
            <div className="col-xl-4 d-none d-xl-flex align-items-center justify-content-center col-img">
              <img src="images/graphics-designer-3-cols.png" alt="" />
            </div>
            <div className="col-md-6 col-xl-4 col-content text-center text-xl-start">
              <div className="content-box">
                <h3>AI-Powered Screening</h3>
                <p> We use advanced AI to select the perfect candidate for you.</p>
              </div>
              <div className="content-box">
                <h3>Hiring and Onboarding</h3>
                <p>Once you select your Graphics Designers, we will take care of the contract and onboarding process.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cmn_btn_grp cmn_btn_grp_center">
          <NavLink to="https://www.sourcedesk.io/schedule-a-call" className="cmn_btn_fill">
            Find Your Desired Graphics Designers
          </NavLink>
        </div>
      </section>
      <section className="developer-faqs full_width fix_padding developer-faqs__bg-1" style={{}}>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>FAQ</h2>
              <div className="text_controller">
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="stppng_accordian">
                <Accordion defaultActiveKey={0}>
                  {FAQsData.map((e, i) => (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>{e.faq}</Accordion.Header>
                      <Accordion.Body> {e.ans} </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width foot_gap pricing_pgbk fix_padding" style={{ background: '#1b212d url(images/cta-bg-sketch-artist.jpg) no-repeat 50% 50%' }}>
        <div className="container">
          <h2>Explore Sourcedesk's Top Graphics Designers to Enhance Your Designing Projects</h2>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="/" className="cmn_btn_stroke">
              Hire Pre-Vetted Graphics Designers
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
