import { NavLink } from "react-router-dom";
import DevProfileCard from "./DevProfileCard";
const data = [
  {
    id: 0,
    profImg: `${window.location.origin}/images/char_male.png`,
    profName: "Archit Mishra",
    skills: {
      featSkills: ["React", "Redux", "JavaScript", "HTML", "CSS"],
      allSkills: [],
    },
    expYears: 5.2,
    location: {
      city: "Noida",
      state: "Delhi",
      country: "India",
    },
  },
  {
    id: 1,
    profImg: `${window.location.origin}/images/char_male.png`,
    profName: "Archit Mishra",
    skills: {
      featSkills: ["React", "Redux", "JavaScript", "HTML", "CSS"],
      allSkills: [],
    },
    expYears: 5.2,
    location: {
      city: "Noida",
      state: "Delhi",
      country: "India",
    },
  },
  {
    id: 2,
    profImg: `${window.location.origin}/images/char_male.png`,
    profName: "Archit Mishra",
    skills: {
      featSkills: ["React", "Redux", "JavaScript", "HTML", "CSS"],
      allSkills: [],
    },
    expYears: 5.2,
    location: {
      city: "Noida",
      state: "Delhi",
      country: "India",
    },
  },
  {
    id: 3,
    profImg: `${window.location.origin}/images/char_male.png`,
    profName: "Archit Mishra",
    skills: {
      featSkills: ["React", "Redux", "JavaScript", "HTML", "CSS"],
      allSkills: [],
    },
    expYears: 5.2,
    location: {
      city: "Noida",
      state: "Delhi",
      country: "India",
    },
  },
  {
    id: 4,
    profImg: `${window.location.origin}/images/char_male.png`,
    profName: "Archit Mishra",
    skills: {
      featSkills: ["React", "Redux", "JavaScript", "HTML", "CSS"],
      allSkills: [],
    },
    expYears: 5.2,
    location: {
      city: "Noida",
      state: "Delhi",
      country: "India",
    },
  },
  {
    id: 5,
    profImg: `${window.location.origin}/images/char_male.png`,
    profName: "Archit Mishra",
    skills: {
      featSkills: ["React", "Redux", "JavaScript", "HTML", "CSS"],
      allSkills: [],
    },
    expYears: 5.2,
    location: {
      city: "Noida",
      state: "Delhi",
      country: "India",
    },
  },
];
export default function DevProfiles( {varientClass, heading, priCTALink, priCTATitle, profiles=data} ) {
  return (
    <section className={`full_width fix_padding ${varientClass}`}>
      <div className="container">
        <div className="text_controller text_controller_center">
          <h2>{heading}
          </h2>
          <hr />
        </div>

        <div className="row">
          {profiles.map((el) => (
            <DevProfileCard el={el} key={el.id} />
          ))}
        </div>

        <div className="cmn_btn_grp cmn_btn_grp_center">
          <NavLink
            to={priCTALink}
            className="cmn_btn_fill"
          >
            {priCTATitle}
          </NavLink>
        </div>
      </div>
    </section>
  );
}
