import React, { useEffect, useState } from 'react';
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";





export default function OurTeamTabs() {

  const get_team_data = "https://www.sourcedesk.io/api/v1/get-team-list";
const [ourTeam, setteamData] = useState([]);

const getteamsData = () => {
  axios
    .get(get_team_data)
    .then((res) => {
      
      setteamData(res.data);

      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
  useEffect(() => {
    getteamsData();
  }, []);

  if(ourTeam.length>0){
  return (
    <section className="full_width ourTeamtab fix_padding">
      <div className="container">
        <div className="text_controller text_controller_center">
          <h2>
            The Catalysts of Our Clients’ Success: <br /> Unveiling Our
            Passionate and Driven Team
          </h2>
          <hr />
        </div>
        <div className="d-none d-md-block">
        <Tabs>
      {ourTeam.map((el, i) => (
        <Tab eventKey={el.dept} title={el.dept} key={i}>
          <div className="row">
            {el.members.map((el, i) => (
              <div
                className="col-12 col-md-3 col-sm-3 col-lg-3 col-xl-3"
                key={i}
              >
                <div className="ourTeamtab_items">
                  <img src={el.photo} alt={el.name} />
                  <h2>{el.name}</h2>
                  <h4>{el.designation}</h4>
                </div>
              </div>
            ))}
          </div>
        </Tab>
      ))}
    </Tabs>

        </div>
        <div className="d-block d-md-none">

        <Accordion defaultActiveKey={null} >
      {ourTeam.map((el, i) => (
        <Accordion.Item eventKey={i} key={i}>
          <Accordion.Header>{el.dept}</Accordion.Header>
          <Accordion.Body>
            <div className="row">
              {el.members.map((member, index) => (
                <div
                  className="col-12 col-md-3 col-sm-3 col-lg-3 col-xl-3"
                  key={index}
                >
                  <div className="ourTeamtab_items">
                    <img src={member.photo} alt={member.name} />
                    <h2>{member.name}</h2>
                    <h4>{member.designation}</h4>
                  </div>
                </div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
        </div>
      </div>
    </section>
  );
}
}


