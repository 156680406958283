// import InnerBanner from "../Components/InnerBanner";
// import TrustedSlider from "../Components/TrustedSlider";
// import BlogsSlider from "../Components/BlogsSlider";
// import Accordion from 'react-bootstrap/Accordion';
import Slider from 'react-slick';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet';
// import { NavLink } from 'react-router-dom';
// import DevProfiles from "../Components/DevProfiles";
// import { NavLink } from "react-router-dom";
// import TalentVTab from "../Components/TalentVTab";
// import Techologies from "../Components/Technologies";
// import { Helmet } from "react-helmet";
function TestimonialsSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
    appendDots: dots => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => <button></button>,
  };
  return (
    <div className="slider-testimonials" id="cmn_slider_btn">
      <Slider {...settings}>
        <div className="slider-testimonials-slides">
          <p>
            "When we needed to rapidly scale our AI/ML development capabilities, we turned to Sourcedesk Global. They delivered a seamless experience, providing us with a curated pool of highly
            skilled and vetted AI/ML developers who were able to hit the ground running. Their thorough screening process ensured that the developers were a perfect fit for our projects, saving us
            valuable time and resources."
          </p>
          <h3>Emily Wilson</h3>
          <h4>Head of Engineering</h4>
          <div className="ratings">
            <img src="images/aiml_rating.png" alt="images" />
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            "Hiring the right AI/ML talent can be a daunting task, but Sourcedesk Global made it seamless for us. Their team truly understood our requirements and provided us with top-notch AI/ML
            developers who have been instrumental in driving our cutting-edge projects. We were impressed by their extensive network of AI/ML professionals and their ability to match us with
            developers who not only had the technical expertise but also shared our vision and values."
          </p>
          <h3>David Thompson</h3>
          <h4>Director of AI/ML</h4>
          <div className="ratings">
            <img src="images/aiml_rating.png" alt="images" />
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            "Sourcedesk Global's AI/ML Developer Hire service was instrumental in helping us build our in-house AI/ML team. They took the time to understand our specific needs and provided us with
            exceptional candidates who not only had the technical expertise but also aligned with our company culture. The developers they sourced have been invaluable assets, driving our AI/ML
            initiatives forward with their innovative mindset and deep knowledge."
          </p>
          <h3>Michael Johnson</h3>
          <h4>CTO</h4>
          <div className="ratings">
            <img src="images/aiml_rating.png" alt="images" />
          </div>
        </div>
      </Slider>
    </div>
  );
}
function MileStoneSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    //   arrows:true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          // dots: true,
          // arrows: false
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          // dots: true,
          // arrows: false
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          // dots: true,
          // arrows: false
          slidesToShow: 1,
        },
      },
    ],
    appendDots: dots => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => <button></button>,
  };
  return (
    <div className="slider-milestones" id="cmn_slider_btn">
      <Slider {...settings}>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2008</div>
            <p>Sourcedesk commenced its operations with web development services.</p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2010</div>
            <p>Sourcedesk commenced its operations with web development services.</p>
          </div>
        </div>
        <div className="slider-milestones-slides">
          <div className="milestone">
            <div className="shape">2012</div>
            <p>Sourcedesk commenced its operations with web development services.</p>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default function About() {
  return (
    <>
      <Helmet>
        <title>Unveiling the Sourcedesk Story: Read More About Us </title>
        <meta name="description" content="Explore the inspiring Sourcedesk story. Dive into our journey, values, and impact. Elevate your understanding of who we are. Read more about us now." />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw " /> */}
        {/* <meta name="robots" content="index,follow" /> */}
      </Helmet>
      <section class="full_width Home_banner inner_banner apply_talent_banner hire_banner">
        <div
          class="Home_banner_sub"
          style={{
            background: '#f9fcfc url(images/banner-about-us.jpg) no-repeat 50% 50%',
            height: '400px',
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12Hom_bnr_left inner_bnr_left">
                <div class="banner_text">
                  <h1 className="mb-0">
                    About <span>Us</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding">
        <div className="container">
          <div className="rol">
            <div className="col">
              <div className="text_controller text_controller_center">
                <h2>
                  <span>Experience</span> Sourcedesk Global
                </h2>
                <hr />
              </div>
              <p className="text-center">We are a team of skilled professionals who offer innovative, world-class solutions to ensure fast growth and high profit.</p>
              <div className="row exp-boxes">
                <div className="col">
                  <div className="exp-box">
                    <div className="num">04</div>
                    <h3>OFFICE LOCATIONS </h3>
                    <p>Headquartered in Canada</p>
                  </div>
                </div>
                <div className="col">
                  <div className="exp-box">
                    <div className="num">155+</div>
                    <h3>TALENTED MINDS</h3>
                  </div>
                </div>
                <div className="col">
                  <div className="exp-box">
                    <div className="num">1200+</div>
                    <h3>CLIENTS</h3>
                  </div>
                </div>
                <div className="col">
                  <div className="exp-box">
                    <div className="num">10+</div>
                    <h3>YEARS</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fix_padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text_controller">
                <h2>Who We Are</h2>
                <hr />
              </div>
              <p>
                Sourcedesk Global is a global service provider offering solutions in Website Design and Development, Software Development, Online Marketing, Mobile Application Development, Creative
                Writing, Business Process Outsourcing, Accounting and Bookkeeping, Data Entry, and Virtual Assistant. Established in 2010, we have served over 600 clients in Canada and the USA in
                different industry segments. We have a team of highly experienced professionals who provide groundbreaking solutions to meet our clients’ diverse business needs.
              </p>
            </div>
            <div className="col-md-6">
              <img src="images/abt-us-que-mark.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="fix_padding">
        <div className="container">
          <div className="text_controller">
            <h2>
              Lorem, ipsum dolor sit amet
              <br /> consectetur adipisicing elit. Quod error at voluptatibus.
            </h2>
            <hr />
          </div>

          <div className="d-none d-lg-block skilltabSec">
            <div className="three_tab">
              <Tabs defaultActiveKey="0" id="fill-tab-example" className="mb-3" fill>
                <Tab eventKey="0" title="Our Approach">
                  <div className="container">
                    <div
                      className="row align-items-center"
                      style={{
                        background: '#f7f9f9',
                        paddingInline: '2rem',
                        paddingBlock: '1rem',
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our services leverage collaborative and progressive methods to consistently achieve targeted benchmarks in every project. We specialize in providing practical solutions to
                          exceed all expectations of our clients.
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="1" title="Our Mission">
                  <div className="container">
                    <div
                      className="row align-items-center"
                      style={{
                        background: '#f7f9f9',
                        paddingInline: '2rem',
                        paddingBlock: '1rem',
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our services leverage collaborative and progressive methods to consistently achieve targeted benchmarks in every project. We specialize in providing practical solutions to
                          exceed all expectations of our clients.
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="2" title="Awards & Recognition">
                  <div className="container">
                    <div
                      className="row align-items-center"
                      style={{
                        background: '#f7f9f9',
                        paddingInline: '2rem',
                        paddingBlock: '1rem',
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our services leverage collaborative and progressive methods to consistently achieve targeted benchmarks in every project. We specialize in providing practical solutions to
                          exceed all expectations of our clients.
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="d-lg-none v_accordion">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Our Approach</Accordion.Header>
                <Accordion.Body className="">
                  <div className="container">
                    <div
                      className="row align-items-center"
                      style={{
                        background: '#f7f9f9',
                        paddingInline: '2rem',
                        paddingBlock: '1rem',
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our services leverage collaborative and progressive methods to consistently achieve targeted benchmarks in every project. We specialize in providing practical solutions to
                          exceed all expectations of our clients.
                        </p>
                      </div>
                      <div className="col-4 d-none d-sm-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Our Mission</Accordion.Header>
                <Accordion.Body className="">
                  <div className="container">
                    <div
                      className="row align-items-center"
                      style={{
                        background: '#f7f9f9',
                        paddingInline: '2rem',
                        paddingBlock: '1rem',
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our services leverage collaborative and progressive methods to consistently achieve targeted benchmarks in every project. We specialize in providing practical solutions to
                          exceed all expectations of our clients.
                        </p>
                      </div>
                      <div className="col-4 d-none d-sm-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Awards & Recognition</Accordion.Header>
                <Accordion.Body className="">
                  <div className="container">
                    <div
                      className="row align-items-center"
                      style={{
                        background: '#f7f9f9',
                        paddingInline: '2rem',
                        paddingBlock: '1rem',
                      }}
                    >
                      <div className="col-8">
                        <p>
                          Our services leverage collaborative and progressive methods to consistently achieve targeted benchmarks in every project. We specialize in providing practical solutions to
                          exceed all expectations of our clients.
                        </p>
                      </div>
                      <div className="col-4 d-none d-sm-flex justify-content-center">
                        <img src="images/about-us-aim-3-tab.png" alt="" />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
      <section
        className="full_width section-testimonials fix_padding"
        style={{
          background: 'url(images/aiml_testimony_bg.jpg) no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row align-items-md-center">
            <div className="col-md-4">
              <h2>
                <span>What Our</span>
                <br />
                <span>Clients have to say</span>
              </h2>
            </div>
            <div className="col-md-8 mt-5 mt-md-0">
              <TestimonialsSlider />
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
      <section className="full_width fix_padding section-cta-horizontal" style={{ background: '#fff' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text_controller">
                <h2 className="mb-2">Sourcedesk Advantage for Hiring Remote Talent</h2>
                <p>Discover the benefit of hiring remote talent from Sourcedesk to elevate your business operations.</p>
              </div>
            </div>
            <div className="col-sm-6 text-sm-end">
              <a href="https://www.sourcedesk.io/schedule-a-call" class="cmn_btn_fill text-nowrap">
                Get Started{' '}
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
      <section className="full_width fix_padding section-milestones" style={{ background: '#f7f9f9' }}>
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Our <span>Milestone</span>
            </h2>
            <hr />
          </div>
          <div className="row">
            <div className="col">
              <MileStoneSlider />
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding section-cta-horizontal" style={{ background: '#fff' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text_controller">
                <h2 className="mb-2">Sourcedesk Advantage for Hiring Remote Talent</h2>
                <p>Discover the benefit of hiring remote talent from Sourcedesk to elevate your business operations.</p>
              </div>
            </div>
            <div className="col-sm-6 text-sm-end">
              <a href="https://www.sourcedesk.io/schedule-a-call" class="cmn_btn_fill text-nowrap">
                Get Started{' '}
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
      <section
        className="full_width fix_padding"
        style={{
          background: "url('images/about-us-cta-bg.png') no-repeat center center",
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="text_controller text_controller_center">
              <h2 className="text-white">GET A FREE CONSULTATION</h2>
              <p className="text-white">Engage an Sourcedesk Global expert to learn more about services and solutions</p>
              <a href="https://www.sourcedesk.io/schedule-a-call" class="cmn_btn_fill cmn_btn_fill_white text-nowrap">
                Get Started{' '}
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"></div>
    </>
  );
}
