import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
import Accordion from "react-bootstrap/Accordion";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import DevProfiles from "../Components/DevProfiles";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ArtificialIntelligence() {
  return (
    <>
      <Helmet>
        <title>Artificial Intelligence | Sourcedesk</title>
        <meta name="title" content="Artificial Intelligence | Sourcedesk" />
        <meta
          name="description"
          content="Artificial Intelligence | Sourcedesk"
        />
        {/* <!-- Canonical & hrefLang --> */}
        <link
          rel="canonical"
          href="https://www.sourcedesk.io/artificial-intelligence"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://www.sourcedesk.io/artificial-intelligence"
        />
        <link
          rel="alternate"
          hrefLang="en-US"
          href="https://www.sourcedesk.io/artificial-intelligence"
        />

        {/* <!-- Twitter Card meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@sourcedeskgl" />
        <meta
          name="twitter:title"
          content="Artificial Intelligence | Sourcedesk"
        />
        <meta
          name="twitter:description"
          content="Artificial Intelligence | Sourcedesk"
        />
        <meta
          name="twitter:image"
          content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg"
        />
        {/* <!-- Facebook Open Graph tags --> */}
        <meta
          property="og:url"
          content="https://www.sourcedesk.io/artificial-intelligence"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Artificial Intelligence | Sourcedesk"
        />
        <meta
          property="og:description"
          content="Artificial Intelligence | Sourcedesk"
        />
        <meta
          property="og:image"
          content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg"
        />
        {/* <!-- Additional Open Graph tags for better customization --> */}
        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1260" />
        <meta property="og:image:alt" content="SourceDesk Global" />
        <meta property="og:locale" content="en_US" />

        {/* <!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

        {/* <!-- Title Bar Icon --> */}
        {/* <link
          rel="icon"
          href="https://www.sourcedesk.io/public/images/favicon.png"
          type="image/x-icon"
        /> */}

        {/* <!-- Head End --> */}

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "@id": "https://www.sourcedesk.io/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.sourcedesk.io/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "AI/ML",
                "item": "https://www.sourcedesk.io/ai-ml"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Hire AI/ML Developer",
                "item": "https://www.sourcedesk.io/artificial-intelligence"
              }
            ]
          }`}
        </script>

        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Artificial Intelligence Development",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.5",
                "bestRating": "5",
                "worstRating": "1",
                "ratingCount": "100" // Example: assuming 100 ratings contributed to the aggregate rating
              }
            },
            "author": {
              "@type": "Person",
              "name": "SourceDesk"
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "reviewBody": "Review Body"
          }`}
        </script>
      </Helmet>
      <InnerBanner
        InnBanner="url('images/ai-banner.png')"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/ai-banner-right.png"
        TitleInn={[
          <span>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </span>,
        ]}
        ParaInn="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        priCTALink="/schedule-a-call"
        priCTATitle="Lorem Ipsum"
        secCTALink={""}
        secCTATitle={""}
      />
      <TrustedSlider defaultTitle={"Trusted By"} />
      <section className="full_width fix_padding section-why-sdg-is-best mt-40">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2 className="text-controller">
              {" "}
              <span>
                Why SDG Is Your Best Choice for Hiring Top-Tier AI/ML Developers
              </span>{" "}
            </h2>
            <p>
              {" "}
              At SDG, we meticulously select the finest AI and machine learning
              developers to power your project's success. Our rigorous vetting
              ensures that only the most qualified professionals are matched to
              your organization's specific needs.{" "}
            </p>
            <hr />
          </div>
          <div className="row mt-40 align-items-center">
            <div className="col-md-6 col-sm-6">
              <div className="">
                {" "}
                <img
                  src="images/ai-accordion.png"
                  alt="Hire ai ml developers"
                />{" "}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="stppng_accordian" id="accordionExample">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Dedicated Professionals</Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      Our AI/ML developers are passionate experts committed to
                      delivering exceptional work. They seamlessly integrate
                      with your team, aligning with your business objectives.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>AI-Driven Matchmaking</Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      Recognizing each organization's unique needs, our
                      AI-powered capabilities, combined with human expertise,
                      enable us to identify the perfect AI/ML professionals for
                      your project.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Successful Delivery</Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      Equipped with the latest tools and technologies, our AI/ML
                      developers ensure successful project completion. We boast
                      a 100% success rate in building high-performing teams that
                      drive the future of the businesses they serve.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Rigorous Vetting</Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      Our vetting process for AI/ML developers is comprehensive.
                      We meticulously evaluate their coding skills, language
                      proficiency, and cultural fit to ensure they are the
                      perfect match for your project.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DevProfiles
        priCTALink="/"
        priCTATitle={"Build Your Team Now"}
        heading={"Lorem Ipsum is simply dummy text of the printing"}
      />
      <section className="full_width fix_padding section-technical-expertise">
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              {" "}
              Unparalleled AI/ML Expertise
              <br />
              on <span>Demand</span>{" "}
            </h2>
            <hr />
          </div>
          <ul className="uniq_infrmtn_down">
            <li>
              <div className="uniq_infrmtn_left">
                <h3>APIs</h3>
              </div>
              <div className="uniq_infrmtn_right">
                {" "}
                DataRobot API | IBM Watson API | Amazon SageMaker API | H2O.ai
                API | TensorFlow API | PyTorch API | Google Cloud AI API | Azure
                Machine Learning API | Databricks API{" "}
              </div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Frameworks</h3>
              </div>
              <div className="uniq_infrmtn_right">
                {" "}
                Apache Spark | H2O.ai | Microsoft Cognitive Toolkit | TensorFlow
                | IBM Watson | Amazon SageMaker | Google Cloud AI Platform |
                PyTorch | Toolkit | Azure Machine Learning | Databricks |
                DataRobot{" "}
              </div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Libraries</h3>
              </div>
              <div className="uniq_infrmtn_right">
                {" "}
                PyTorch | Scikit-learn | Keras | Pandas | NumPy | Matplotlib |
                NLTK | OpenCV | TensorFlow | Hugging Face Transformers | FastAI
                | Spark NLP | Dask | Prophet{" "}
              </div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Databases</h3>
              </div>
              <div className="uniq_infrmtn_right">
                {" "}
                TensorFlow Extended (TFX) | MLflow | Feast | Polyaxon | Kubeflow
                | Apache Airflow | DVC | Pachyderm | Seldon Core | KubeFlow{" "}
              </div>
            </li>
            <li>
              <div className="uniq_infrmtn_left">
                <h3>Platforms</h3>
              </div>
              <div className="uniq_infrmtn_right">
                {" "}
                Amazon Web Services (AWS) | Google Cloud Platform (GCP) |
                Microsoft Azure | IBM Cloud | Databricks | DataRobot | H2O.ai |
                Alibaba Cloud | Oracle Cloud | SAP Leonardo{" "}
              </div>
            </li>
          </ul>
          <div className="cmn_btn_grp cmn_btn_grp_center cmn_btn_exchange cmn_btn_wide">
            {" "}
            <NavLink to="/schedule-a-call" className="cmn_btn_stroke">
              Recruit Top AI/ML Talent
            </NavLink>{" "}
            <NavLink to="/hire-development-team" className="cmn_btn_fill">
              Build Your Dream Engineering Team
            </NavLink>{" "}
          </div>
        </div>
      </section>
      <section
        class="full_width bg_dark_blue fix_padding"
        style={{ background: "#1b212d" }}
      >
        <div class="container">
          <div class="text_controller">
            <h2>
              Streamlined Process to Hire Our Elite Remote{" "}
              <span> ReactJS Developers</span>
            </h2>
            <hr />
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 react_ft_cnt">
              <ul>
                <li>
                  <h3>Lorem, ipsum dolor.</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Natus, aut.
                  </p>
                </li>
                <li>
                  <h3>Lorem, ipsum dolor.</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Natus, aut.
                  </p>
                </li>
                <li>
                  <h3>Lorem, ipsum dolor.</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Natus, aut.
                  </p>
                </li>
                <li>
                  <h3>Lorem, ipsum dolor.</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Natus, aut.
                  </p>
                </li>
              </ul>
              <div class="cmn_btn_grp">
                {" "}
                <a
                  href="https://www.sourcedesk.io/schedule-a-call"
                  class="cmn_btn_fill"
                >
                  Lorem, ipsum dolor.
                </a>{" "}
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="react_ft_img">
                {" "}
                <img src="images/ai-points.png" alt="react_ft_img" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-cta-form-full full-width fix_padding">
        <div class="container">
          <div class="row mb-5">
            <div class="col">
              <h2 class="text_controller text_controller_center">
                {" "}
                <span>
                  Revolutionize Your Business with Sourcedesk Global's AI and ML
                  Solutions
                </span>
                <br />{" "}
                <span>
                  Unlock Unprecedented Efficiency and ROI – Schedule a
                  Consultation with Our Experts
                </span>{" "}
              </h2>
              <div class="text_controller text_controller_center">
                <hr />
                <h3>
                  {" "}
                  Schedule a Call With Our AI Experts to Create Robust and
                  Real-Time AI and ML Solutions{" "}
                </h3>
              </div>
            </div>
          </div>
          <div class="row mx-md-5 px-md-5 mx-sm-2 px-sm-2">
            <form
              action="https://crm.zoho.com/crm/WebToLeadForm"
              name="WebToLeads6057464000001083017"
              method="POST"
            >
              <input
                type="hidden"
                name="_token"
                value="i5mkriFaXQX1URII7PPA3thM9jt2FRxobCT4AUZu"
              ></input>
              <input
                type="hidden"
                name="xnQsjsdp"
                value="7e1fcdb34e2a213ab3ba38616659a51d379b6c0fe4b7384d7403655a5defc861"
              ></input>
              <input type="hidden" name="zc_gad" id="zc_gad" value=""></input>
              <input
                type="hidden"
                name="xmIwtLD"
                value="c0a38f9f3329b3a20dd6c13074c4dada3eb9daa6ea764f932498c473e929194dc519d95864df6fc0443107dfd5c400c9"
              ></input>
              <input type="hidden" name="actionType" value="TGVhZHM="></input>
              <input
                type="hidden"
                name="returnURL"
                value="https://www.sourcedesk.io/thank-you?thank-you=2"
              ></input>
              <div class="col">
                <div class="row mb-0">
                  <div class="col-md-6">
                    {" "}
                    <label for="name" class="if__required">
                      FIRST NAME
                    </label>{" "}
                    <input
                      type="text"
                      class="w-100"
                      id="First_Name"
                      name="First Name"
                      pattern="[a-zA-Z\s]*"
                      title="Please use only alphabets"
                      required
                    />{" "}
                  </div>
                  <div class="col-md-6">
                    {" "}
                    <label for="name" class="if__required">
                      LAST NAME
                    </label>{" "}
                    <input
                      type="text"
                      class="w-100"
                      id="Last_Name"
                      name="Last Name"
                      pattern="[a-zA-Z\s]*"
                      title="Please use only alphabets"
                      required
                    />{" "}
                  </div>
                </div>
                <div class="row mb-0">
                  <div class="col-md-6">
                    {" "}
                    <label for="email" class="if__required">
                      EMAIL
                    </label>{" "}
                    <input
                      type="email"
                      class="w-100"
                      id="Email"
                      name="Email"
                      required
                    />{" "}
                  </div>
                  <div class="col-md-6">
                    {" "}
                    <label for="phone" class="if__required">
                      PHONE NO.
                    </label>{" "}
                    <input
                      type="tel"
                      class="w-100"
                      id="Phone"
                      name="Phone"
                      maxlength="10"
                      minlength="10"
                      pattern="^[0-9]+$"
                      title="Please use only numbers"
                      required
                    />{" "}
                  </div>
                </div>
                <div class="row mb-0">
                  <div class="col-md-6">
                    {" "}
                    <label for="company-name" class="if__required">
                      COMPANY NAME
                    </label>{" "}
                    <input
                      type="text"
                      class="w-100"
                      id="Company"
                      name="Company"
                      required
                    />{" "}
                  </div>
                  <div class="col-md-6">
                    {" "}
                    <label for="website">COMPANY WEBSITE</label>{" "}
                    <input
                      type="text"
                      class="w-100"
                      id="LEADCF16"
                      name="LEADCF16"
                    />{" "}
                  </div>
                </div>
                <div class="row mb-0">
                  <div class="col">
                    {" "}
                    <label for="message" class="if__required">
                      MESSAGE
                    </label>{" "}
                    <textarea
                      id="Description"
                      name="Description"
                      cols="30"
                      rows="10"
                      class="w-100"
                      required
                    ></textarea>{" "}
                  </div>
                </div>
                <div class="row mb-0">
                  <div class="col text-center">
                    {" "}
                    <input type="submit" value="Submit" />{" "}
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }}>
                <div class="zcwf_row wfrm_fld_dpNn">
                  <div
                    class="zcwf_col_lab"
                    style={{ fontSize: "12px", fontFamily: "Arial" }}
                  >
                    <label for="LEADCF1">Enquiry Type</label>
                  </div>
                  <div class="zcwf_col_fld">
                    <select
                      class="zcwf_col_fld_slt"
                      id="LEADCF1"
                      name="LEADCF1"
                    >
                      <option value="-None-">-None-</option>
                      <option value="VBK Sales Flow">VBK Sales Flow</option>
                      <option value="SEO-PPC-Website Development Flow">
                        SEO-PPC-Website Development Flow
                      </option>
                      <option value="CDAP Sales Flow">CDAP Sales Flow</option>
                      <option selected="" value="SourceDeskio Leads">
                        SourceDeskio Leads
                      </option>
                    </select>
                    <div class="zcwf_col_help"></div>
                  </div>
                </div>
                <div class="zcwf_row wfrm_fld_dpNn">
                  <div
                    class="zcwf_col_lab"
                    style={{ fontSize: "12px", fontFamily: "Arial" }}
                  >
                    <label for="Lead_Source">Lead Source</label>
                  </div>
                  <div class="zcwf_col_fld">
                    <select
                      class="zcwf_col_fld_slt"
                      id="Lead_Source"
                      name="Lead Source"
                    >
                      <option value="-None-">-None-</option>
                      <option selected="" value="Website">
                        Website
                      </option>
                      <option value="Google Search">Google Search</option>
                      <option value="Chat">Chat</option>
                      <option value="Word of Mouth">Word of Mouth</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Inbound Email">Inbound Email</option>
                      <option value="Inbound Call">Inbound Call</option>
                    </select>
                    <div class="zcwf_col_help"></div>
                  </div>
                </div>
                <div class="zcwf_row wfrm_fld_dpNn">
                  <div
                    class="zcwf_col_lab"
                    style={{ fontSize: "12px", fontFamily: "Arial" }}
                  >
                    <label for="Lead_Status">Lead Status</label>
                  </div>
                  <div class="zcwf_col_fld">
                    <select
                      class="zcwf_col_fld_slt"
                      id="Lead_Status"
                      name="Lead Status"
                    >
                      <option value="-None-">-None-</option>
                      <option selected="" value="Not Contacted">
                        Not Contacted
                      </option>
                      <option value="Contacted">Contacted</option>
                      <option value="Meeting Scheduled">
                        Meeting Scheduled
                      </option>
                      <option value="Interested">Interested</option>
                      <option value="Junk Lead">Junk Lead</option>
                      <option value="Lost Lead">Lost Lead</option>
                      <option value="Pre-Qualified">Pre-Qualified</option>
                    </select>
                    <div class="zcwf_col_help"></div>
                  </div>
                </div>
                <div class="zcwf_row wfrm_fld_dpNn">
                  <div
                    class="zcwf_col_lab"
                    style={{ fontSize: "12px", fontFamily: "Arial" }}
                  >
                    {" "}
                    <label for="LEADCF17">Submitted from page</label>
                  </div>
                  <div class="zcwf_col_fld">
                    <input
                      type="text"
                      id="LEADCF17"
                      name="LEADCF17"
                      maxlength="255"
                      value="https://www.sourcedesk.io/ai-ml-development"
                    />
                    <div class="zcwf_col_help"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section
        class="full_width foot_gap pricing_pgbk fix_padding"
        style={{
          background:
            'url("images/cta-bg-sketch-artist.jpg") 50% 50% no-repeat rgb(27, 33, 45)',
        }}
      >
        <div class="container">
          <h2>Lorem ipsum dolor sit amet.</h2>
          <div class="cmn_btn_grp cmn_btn_grp_center">
            <a href="/" class="cmn_btn_stroke">
              Lorem, ipsum.
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

const ResponsiveSlider = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {isMobile ? (
        <Slider {...settings}>
          <div>
            <h3>Slide 1</h3>
          </div>
          <div>
            <h3>Slide 2</h3>
          </div>
          <div>
            <h3>Slide 3</h3>
          </div>
          <div>
            <h3>Slide 4</h3>
          </div>
        </Slider>
      ) : (
        <div>
          <div>
            <h3>Slide 1</h3>
          </div>
          <div>
            <h3>Slide 2</h3>
          </div>
          <div>
            <h3>Slide 3</h3>
          </div>
          <div>
            <h3>Slide 4</h3>
          </div>
        </div>
      )}
    </div>
  );
};
