import React from 'react'
import { NavLink } from 'react-router-dom';

function Homepgbrk() {
  return (
    <>
        <section className="full_width home_pgbk fix_padding">
            <div className="container">
                <div className="text_controller text_controller_center">
                    <h2>Access a Network of Top-Tier Talent</h2>  
                </div>
                <div className="cmn_btn_grp cmn_btn_grp_center">
                    <NavLink to="/" onClick={e=>e.preventDefault()} className="cmn_btn_stroke">Download Our Transparent Pricing Guide</NavLink>
                    <NavLink to="/schedule-a-call" className="cmn_btn_fill">Collaborate with the Best in Business</NavLink>
                </div>
            </div>        
        </section>
    </>
  )
}

export default Homepgbrk;