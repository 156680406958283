export default function Process() {
  return (
    <section class="full_width foot_gap react_ft_content bg_dark_blue fix_padding">
      <div class="container">
        <div class="text_controller">
          <h2>
            Streamlined Process to Hire Our Elite Remote{" "}
            <span> ReactJS Developers</span>
          </h2>
          <hr />
        </div>

        <div class="row">
          <div class="col-sm-6 col-md-6 react_ft_cnt">
            <ul>
              <li>
                <h3>Specify Your Skill Requirements</h3>
                <p>
                  Tell us what skillset you need, and we will promptly schedule
                  a call to comprehensively understand your specific needs.
                </p>
              </li>

              <li>
                <h3>Leverage Advanced Algorithm and HR Expertise</h3>
                <p>
                  Our cutting-edge algorithm, supported by HR professionals,
                  efficiently selects top-tier talent, providing pre-vetted
                  candidates on your personalized dashboard.
                </p>
              </li>

              <li>
                <h3>Schedule Interviews Seamlessly</h3>
                <p>
                  Count on our dedicated Account Manager to effortlessly
                  coordinate interviews. This ensures a streamlined process that
                  aligns with your availability and preferences.
                </p>
              </li>

              <li>
                <h3>Get a Risk-Free Trial</h3>
                <p>
                  Start your project with confidence with our two-week risk-free
                  trial period, enabling you to assess our developers' skills
                  and capabilities firsthand.
                </p>
              </li>
            </ul>

            <div class="cmn_btn_grp">
              <a
                href="https://www.sourcedesk.io/schedule-a-call"
                class="cmn_btn_fill"
              >
                Unlock ReactJS Excellence
              </a>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div class="react_ft_img">
              <img
                src="images/react_ft_img.png"
                alt="react_ft_img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
