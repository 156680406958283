import { NavLink } from "react-router-dom";
// import DevProfiles from "../Components/DevProfiles";
import InnerBanner from "../Components/InnerBanner";
import { Helmet } from "react-helmet";
// import TrustedSlider from "../Components/TrustedSlider";

export default function AiMl() {
  return <>
    <InnerBanner
        InnBanner="url('images/banner-ai-ml.jpg')"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/aiml_s_ban_img.png"
        TitleInn={["Unleash the Power of AI/ML",<span><br />Transform Your Business</span>]}
        ParaInn="Accelerate Innovation, Optimize Processes, and Unlock Limitless Possibilities"
        priCTALink="/ai-ml-development" priCTATitle="Get Custom AI/ML Solutions" secCTALink={"/schedule-a-call"} secCTATitle={"Hire Remote AI/ML Developers"}
    />
    <Helmet>
    {/* <meta charset="UTF-8" /> */}
{/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
<title>Dedicated Hiring Resource - AI/ML</title>

{/* <!-- Head  -->
    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />


    {/* <!-- Head End --> */}
    </Helmet>
    <section className="full_width aiml_solution fix_padding">
      <div className="container">
        <div className="text_controller text_controller_center mb-5">
          <h2>Navigating the AI/ML Landscape: Your Guide to Success</h2>
        </div>

        <div className="aiml_zigzag">
          <div className="row g-0 align-items-center">
            <div className="col-md-6 aiml_sol_content text_controller">
                <h4>Bespoke AI/ML Solutions for Your Unique Needs</h4>
                <p>Unlock the full potential of AI/ML for your business with our tailored development services. Our team of experts will collaborate with you to understand your unique requirements and craft bespoke solutions that will integrate seamlessly into your existing systems. From ideation to deployment, we'll be your trusted partner, ensuring your AI/ML projects are executed precisely and efficiently. Leverage our cutting-edge technologies, industry-leading practices, and deep domain expertise to drive innovation, optimize processes, and gain a competitive edge in your market.</p>
            </div>

            <div className="col-md-6 aiml_sol_graphic text-center">
              <img src="images/aiml_s_sol_1.png" alt="images" />
            </div>
          </div>

          <div className="row g-0 align-items-center">
            <div className="col-md-6 aiml_sol_content text_controller">
                <h4>Unleash AI/ML Excellence with Our Remote Talent Pool</h4>
                <p>Tap into a global pool of top-tier AI/ML talent with our remote, dedicated developer solutions. Whether you're seeking specialists in machine learning, deep learning, natural language processing, computer vision, or any other AI/ML domain, we've got you covered. Our rigorous vetting process ensures you'll be working with highly skilled and experienced professionals who are handpicked to meet your project's specific needs. Enjoy the flexibility of scaling your team up or down as required while benefiting from cost-effective rates and seamless collaboration facilitated by our robust remote working infrastructure.</p>
            </div>

            <div className="col-md-6 aiml_sol_graphic text-center">
              <img src="images/aiml_s_sol_2.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
    </section>  
    <section className="full_width aiml_expertise fix_padding">
      <div className="container">
        <div className="text_controller text_controller_center mb-5">
          <h2>SourceDesk’s Technical <span>AI/ML Expertise</span></h2>
          <p>Unlock the full potential of artificial intelligence and machine learning with Sourcedesk's cutting-edge technical expertise. Our team of seasoned professionals possesses deep knowledge in advanced algorithms, neural networks, and data modeling techniques, enabling us to craft innovative solutions tailored to your unique business challenges. Leverage our mastery of AI/ML technologies to drive efficiency, enhance decision-making, and gain a competitive edge in your industry. </p>
        </div>

        <div className="aiml_exp_main">
          <div className="expert_box">
            <h5>APIs –</h5>
            <p>DataRobot API | IBM Watson API | Amazon SageMaker API | H2O.ai API
                  | TensorFlow API | PyTorch API | Google Cloud AI API | Azure Machine
                  Learning API | Databricks API</p>
          </div>

          <div className="expert_box">
            <h5>Frameworks –</h5>
            <p> Apache Spark | H2O.ai | Microsoft Cognitive Toolkit | TensorFlow | IBM Watson | Amazon SageMaker | Google Cloud AI Platform | PyTorch | 
                  Toolkit | Azure Machine Learning | Databricks | DataRobot </p>
          </div>

          <div className="expert_box">
            <h5>Libraries –</h5>
            <p>PyTorch | Scikit-learn | Keras | Pandas | NumPy |
                  Matplotlib | NLTK | OpenCV | TensorFlow | Hugging Face Transformers | FastAI |
                  Spark NLP | Dask | Prophet</p>
          </div>

          <div className="expert_box">
            <h5>Databases –</h5>
            <p>TensorFlow Extended (TFX) | MLflow | Feast | Polyaxon | Kubeflow |
                  Apache Airflow | DVC | Pachyderm | Seldon Core | KubeFlow</p>
          </div>

          <div className="expert_box">
            <h5>Platforms –</h5>
            <p>Amazon Web Services (AWS) | Google Cloud Platform (GCP) |
                  Microsoft Azure | IBM Cloud | Databricks | DataRobot | H2O.ai |
                  Alibaba Cloud | Oracle Cloud | SAP Leonardo</p>
          </div>
        </div>

        <div className="cmn_btn_grp cmn_btn_grp_center mt-0">
          <NavLink to="/ai-ml-development" className="cmn_btn_fill">Unleash AI/ML Innovation for Your Business </NavLink>
          <NavLink to="/hire-ai-ml-developer" className="cmn_btn_stroke">Accelerate Innovation with Elite Remote AI/ML Talent</NavLink>
        </div>
      </div>
    </section>
    <section className="full_width section-cta-form-full fix_padding">
      <div className="container">
        <div className="row mb-5">
          <div className="col">
            <h2 className="text_controller text_controller_center">
              <span>Supercharge Growth with Cutting-Edge AI and ML Solutions</span><br />
              <span>Embark on a Transformative Journey with Sourcedesk</span>
            </h2>
            <div className="text_controller text_controller_center">
              <hr />
              <h2>Connect with Us</h2>
            </div>
          </div>
        </div>
        <div className="row mx-md-5 px-md-5 mx-sm-2 px-sm-2">
          
          <form id="webform6057464000001083017" action="https://crm.zoho.com/crm/WebToLeadForm" name="WebToLeads6057464000001083017" method="POST"  accept-charset="UTF-8">

            <input type="hidden" name="_token" value="wJJUUXYgoALrsmox9h69pkzltPClyxS0d9lfB23d" />
              <input type="text" style={{display:"none"}} name="xnQsjsdp" value="7e1fcdb34e2a213ab3ba38616659a51d379b6c0fe4b7384d7403655a5defc861" /> 
              <input type="hidden" name="zc_gad" id="zc_gad" value="" />
              <input type="text" style={{display:"none"}} name="xmIwtLD" value="c0a38f9f3329b3a20dd6c13074c4dada3eb9daa6ea764f932498c473e929194dc519d95864df6fc0443107dfd5c400c9" /> 
              <input type="text" style={{display:"none"}} name="actionType" value="TGVhZHM=" />
              <input type="text" style={{display:"none"}} name="returnURL" value="https://www.sourcedesk.io/thank-you?thank-you=2" /> 
              


                <div className="col">
                  <div className="row mb-0">
                  <div className="col-md-6">
                      <label for="name" className="if__required">FIRST NAME</label>
                      <input type="text" className="w-100" id="First_Name" name="First Name" pattern="[a-zA-Z\s]*" title="Please use only alphabets" required />
                    </div>
                    <div className="col-md-6">
                      <label for="name" className="if__required">LAST NAME</label>
                      <input type="text" className="w-100" id="Last_Name" name="Last Name" pattern="[a-zA-Z\s]*" title="Please use only alphabets" required />
                    </div>

                  </div>
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <label for="email" className="if__required">EMAIL</label>
                      <input type="email" className="w-100" id="Email" name="Email" required />
                    </div>
                    <div className="col-md-6">
                      <label for="phone" className="if__required">PHONE NO.</label>
                      <input type="tel" className="w-100" id="Phone" name="Phone" maxlength="10" minlength="10" pattern="^[0-9]+$" title="Please use only numbers" required />
                    </div>
                  </div>
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <label for="company-name" className="if__required">COMPANY NAME</label>
                      <input type="text" className="w-100" id="Company" name="Company" required/>
                    </div>
                    <div className="col-md-6">
                      <label for="website">COMPANY WEBSITE</label>
                      <input type="text" className="w-100" id="LEADCF16" name="LEADCF16" />
                    </div>
                  </div>
                  <div className="row mb-0">
                    <div className="col">
                      <label for="message" className="if__required">MESSAGE</label>
                      <textarea id="Description" name="Description" cols="30" rows="10" className="w-100" required></textarea>
                    </div>
                  </div>

                  <div className="row mb-0">
                    <div className="col text-center">
                      <input type="submit" value="Submit" />
                    </div>
                  </div>
                </div>

                <div style={{display:"none"}}>
              <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="LEADCF1">Enquiry Type</label></div><div className="zcwf_col_fld"><select className="zcwf_col_fld_slt" id="LEADCF1" name="LEADCF1">
              <option value="-None-">-None-</option>
              <option value="VBK Sales Flow">VBK Sales Flow</option>
              <option value="SEO-PPC-Website Development Flow">SEO-PPC-Website Development Flow</option>
              <option value="CDAP Sales Flow">CDAP Sales Flow</option>
              <option selected="" value="SourceDeskio Leads">SourceDeskio Leads</option>
              </select><div className="zcwf_col_help"></div></div></div>

              <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="Lead_Source">Lead Source</label></div><div className="zcwf_col_fld"><select className="zcwf_col_fld_slt" id="Lead_Source" name="Lead Source">
              <option value="-None-">-None-</option>
              <option selected="" value="Website">Website</option>
              <option value="Google Search">Google Search</option>
              <option value="Chat">Chat</option>
              <option value="Word of Mouth">Word of Mouth</option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="Inbound Email">Inbound Email</option>
              <option value="Inbound Call">Inbound Call</option>
            </select><div className="zcwf_col_help"></div></div></div>

            <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="Lead_Status">Lead Status</label></div><div className="zcwf_col_fld"><select className="zcwf_col_fld_slt" id="Lead_Status" name="Lead Status">
              <option value="-None-">-None-</option>
            <option selected="" value="Not Contacted">Not Contacted</option>
              <option value="Contacted">Contacted</option>
              <option value="Meeting Scheduled">Meeting Scheduled</option>
              <option value="Interested">Interested</option>
              <option value="Junk Lead">Junk Lead</option>
              <option value="Lost Lead">Lost Lead</option>
              <option value="Pre-Qualified">Pre-Qualified</option>
            </select><div className="zcwf_col_help"></div></div></div>

            <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}>
              <label for="LEADCF17">Submitted from page</label></div><div className="zcwf_col_fld"><input type="text" id="LEADCF17" name="LEADCF17" maxlength="255" value="https://www.sourcedesk.io/ai-ml" /><div className="zcwf_col_help"></div></div></div>
              
                </div>



          </form>
        </div>
      </div>
    </section>
  </>
}