// import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LocationMap from "./LocationMap";

function Footer() {
  // const [curAddress, setCurAddress] = useState(1);
  // function handleClick(add) {
  //   setCurAddress(add);
  // }
  return (
    <>
      <footer className="full_width">
        <div className="footer_sub">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                <div className="footer_sub_logo">
                  <NavLink to="/">
                    <img src={window.location.origin + '/images/logo_black.png'} alt="Logo" />
                  </NavLink>
                </div>
                {/* <div className="footer_sub_map">
                  <img src={window.location.origin + '/images/world_map.png'} alt="Location map" />
                  <div id="horizontalTab2">
                    <ul className="resp-tabs-list">
                      <li
                        onClick={() => handleClick(1)}
                        className={`resp-tab-item ${
                          curAddress === 1 ? "resp-tab-active" : ""
                        }`}
                      ></li>
                      <li
                        onClick={() => handleClick(2)}
                        className={`resp-tab-item ${
                          curAddress === 2 ? "resp-tab-active" : ""
                        }`}
                      ></li>
                      <li
                        onClick={() => handleClick(3)}
                        className={`resp-tab-item ${
                          curAddress === 3 ? "resp-tab-active" : ""
                        }`}
                      ></li>
                      <li
                        onClick={() => handleClick(4)}
                        className={`resp-tab-item ${
                          curAddress === 4 ? "resp-tab-active" : ""
                        }`}
                      ></li>
                    </ul>

                    <div className="resp-tabs-container">
                      <div className={curAddress === 1 ? "d-block" : "d-none"}>
                        <div className="footer_sub_loaction ">
                          <h2>Canada</h2>
                          <h3>Toronto</h3>
                          <p>
                            5399 Eglinton Ave. <br /> West Suite 212,
                            <br /> Toronto, ON, M9C 5K6
                          </p>
                        </div>
                      </div>

                      <div className={curAddress === 2 ? "d-block" : "d-none"}>
                        <div className="footer_sub_loaction ">
                          <h2>Colombia</h2>
                          <h3>Medellin</h3>
                          <p>
                            Cra. 42 #3 Sur 81 Torre 1 Piso 15,
                            <br /> El Poblado,
                            <br /> Medellín, Antioquia, Colombia
                          </p>
                        </div>
                      </div>

                      <div className={curAddress === 3 ? "d-block" : "d-none"}>
                        <div className="footer_sub_loaction">
                          <h2>India</h2>
                          <h3>Kolkata</h3>
                          <p>
                            Shaila Tower, EP-204, <br /> Salt Lake, Sector-V,
                            <br /> Kolkata - 700091 <br />
                            info@sourcedeskglobal.com
                          </p>
                        </div>
                      </div>

                      <div className={curAddress === 4 ? "d-block" : "d-none"}>
                        <div className="footer_sub_loaction">
                          <h2>USA</h2>
                          <h3>New York</h3>
                          <p>
                            One Liberty Plaza, <br /> 165 Broadway,
                            <br /> Suite 2301, New York, 10006
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <LocationMap />
              </div>

              <div className="col-12 col-md-8 col-sm-12 col-lg-8 col-xl-8">
                <div className="row quick_links">
                  <div className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4">
                    <h2>Developer</h2>
                    <ul>
                      <li>
                        <NavLink to="/schedule-a-call">Hire Developer By Specific Skills</NavLink>
                      </li>
                      <li>
                        <NavLink to="/hire-development-team">Hire Development Team</NavLink>
                      </li>
                      <li>
                        <NavLink to="/schedule-a-call">Book A Call</NavLink>
                      </li>
                      <li>
                        <NavLink to="/job-description">Job Description Example</NavLink>
                      </li>
                      <li>
                        <NavLink to="/employer-referral">Employer Referral</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6 col-sm-6 col-lg-3 col-xl-3">
                    <h2>Candidate</h2>
                    <ul>
                      <li>
                        <NavLink to="/employee-referral">Employee Referral</NavLink>
                      </li>
                      <li>
                        <NavLink to="/resume-guide">Resume Guide</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6 col-sm-6 col-lg-2 col-xl-2">
                    <h2>Support</h2>
                    <ul>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/terms-service">Terms of Service</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6 col-sm-6 col-lg-3 col-xl-3">
                    <h2>About Us</h2>
                    <ul>
                      <li>
                        <NavLink to="/social-responsibility">Social Responsibility</NavLink>
                      </li>
                      <li>
                        <NavLink to="/events">Events</NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer-review">Customer Reviews</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog">Blog</NavLink>
                      </li>
                      <li>
                        <NavLink to="/career">Career</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/">Culture and Values</NavLink>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="connect_us">
                    <h2>Connect With Us</h2>
                    <ul>
                      <li>
                        <NavLink target="_blank" to="https://www.instagram.com/sourcedeskglobal/">
                          <img src={window.location.origin + '/images/instagram.png'} alt="Instagram" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink target="_blank" to="https://www.facebook.com/sourcedeskglobal/">
                          <img src={window.location.origin + '/images/fb.png'} alt="Facebook" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink target="_blank" to="https://twitter.com/sourcedeskgl">
                          <img src={window.location.origin + '/images/sdgv_twitter_x.png'} alt="Twitter" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink target="_blank" to="https://linkedin.com/company/sourcedesk-global">
                          <img src={window.location.origin + '/images/in.png'} alt="LinkedIn" />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink target="_blank" to="https://www.youtube.com/channel/UCVL_IzU8tO6RA8n2P5ahUjw">
                          <img src={window.location.origin + '/images/youtube.png'} alt="YouTube" />
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container justify-content-center">
            <p>© Copyright 2024, Sourcedesk Global All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
