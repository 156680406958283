import { NavLink } from "react-router-dom";
import DevProfiles from "../Components/DevProfiles";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";

export default function DigitalMarketers() {
  return <>
  <Helmet>
  <title>Pre-Vetted Remote Digital Marketers | Management to Execution</title>
<meta name="description" content="Sourcedesk delivers excellence in remote digital marketing. Explore our pre-vetted specialists for seamless management to execution. Elevate your brand now." />
<meta name="title" content="Pre-Vetted Remote Digital Marketers | Management to Execution" />
    {/* <!-- Canonical & hrefLang --> */}
    <link rel="canonical" href="https://www.sourcedesk.io/digital-marketers" />
    <link rel="alternate" hrefLang="x-default" href="https://www.sourcedesk.io/digital-marketers" />
    <link rel="alternate" hrefLang="en-US" href="https://www.sourcedesk.io/digital-marketers" />

    {/* <!-- Twitter Card meta tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@sourcedeskgl" />
    <meta name="twitter:title" content="Pre-Vetted Remote Digital Marketers | Management to Execution" />
    <meta name="twitter:description" content="Sourcedesk delivers excellence in remote digital marketing. Explore our pre-vetted specialists for seamless management to execution. Elevate your brand now." />
    <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />
    {/* <!-- Facebook Open Graph tags --> */}
    <meta property="og:url" content="https://www.sourcedesk.io/digital-marketers" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Pre-Vetted Remote Digital Marketers | Management to Execution" />
    <meta property="og:description" content="Sourcedesk delivers excellence in remote digital marketing. Explore our pre-vetted specialists for seamless management to execution. Elevate your brand now." />
    <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />
    {/* <!-- Additional Open Graph tags for better customization --> */}
    <meta property="og:image:width" content="2400" />
    <meta property="og:image:height" content="1260" />
    <meta property="og:image:alt" content="SourceDesk Global" />
    <meta property="og:locale" content="en_US" />

{/* <!----  Head    -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />

{/* <!-- Head end --> */}
<script type="application/ld+json">
{`{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "@id": "https://www.sourcedesk.io/#breadcrumb",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Hire Our Genius Minds",
      "item": "javascript:void(0)"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Digital Marketers",
      "item": "https://www.sourcedesk.io/digital-marketers"
    }
  ]
}`}
</script>
<script type="application/ld+json">
{`{
  "@context": "http://schema.org",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "Digital Marketers",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    }
  },
  "author": {
    "@type": "Person",
    "name": "SourceDesk"
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4.5",
    "bestRating": "5",
    "worstRating": "1"
  },
  "reviewBody": "Review Body"
}`}
</script>
  </Helmet>
    <InnerBanner
        InnBanner="url('images/digital_mark_banner.jpg')"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/digital_mark_right_img.png"
        TitleInn={["Discover Ideal ",<span>Digital Marketing</span>," Talent for Your Business"]}
        ParaInn="Tap into PPC, SEO, and Programmatic Advertising Power with Our Vetted Experts"
        priCTALink="/schedule-a-call" priCTATitle="Elevate Your Online Presence" secCTALink={""} secCTATitle={""}
      />
    <TrustedSlider defaultTitle={"Trusted By"} />
    <section className="full_width qa_every_tlnt digital_mark_parameter fix_padding">
      <div className="container">
          <div className="text_controller">
              <h2>Every talent in our Network is Pre-vetted and <br />Qualified on below Parameters.</h2>  
              <hr />
          </div>
          <div className="row Human_img_bottom">
              <div className="col-12 col-md-7 col-sm-7 col-xl-7 Hmn_img_bt_left">
                  <ul>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Excellent Communication</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Culturally Fit</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Technically <br />Super Sound</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Problem solving <br />abilities</div>
                      </li>
                      <li>
                          <div className="Hmn_bt_left_border"></div>
                          <div className="Hmn_bt_left_text">Self-discipline and <br />time management skills</div>
                      </li>
                  </ul>
              </div>
              <div className="col-12 col-md-5 col-sm-5 col-xl-5 Hmn_img_bt_right"><img src="images/digital_mark_parameter_img.png" alt="Digital Marketing Service" /></div>
              <div className="cmn_btn_grp">
                  <NavLink to="/schedule-a-call" className="cmn_btn_fill">Hire Digital Marketers</NavLink>
                  <NavLink to="/apply-as-talent" className="cmn_btn_stroke">Apply Here</NavLink>
              </div>
          </div>
      </div>
    </section>
    <section className="full_width stepping_stones digital_mark_accordion fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2>Comprehensive Services for Achieving Your Marketing Goals</h2>  
                <hr />               
            </div>

            <div className="row">
                <div className="col-12 col-md-5 col-sm-5 col-xl-5 We_hrns_image">
                    <img src="images/dm_accordion_img.png" alt="PPC Advertising Services" />        
                </div>
                <div className="col-12 col-md-7 col-sm-12 col-xl-7 We_hrns_usp">
                    <Accordion defaultActiveKey="0" className="stppng_accordian">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>PPC Advertising: Drive Results with Targeted Campaigns</Accordion.Header>
                          <Accordion.Body>
                              Our PPC experts maximize ROI across platforms like Google Ads and Bing Ads, with conversion tracking for precise campaign performance analysis.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>SEO: Enhance Your Online Visibility and Rankings</Accordion.Header>
                          <Accordion.Body>
                              Our SEO experts excel in on-page/off-page optimization, comprehensive keyword research, content creation, and technical SEO to boost organic rankings, and attract targeted traffic.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Programmatic Advertising: Leverage Data-Driven Campaigns</Accordion.Header>
                          <Accordion.Body>
                              Our programmatic experts use data-driven tech for precise, multi-channel campaigns targeting the right audience and optimizing for impact and conversions.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Analytics and Reporting: Get Actionable Insights for Optimal Performance</Accordion.Header>
                          <Accordion.Body>
                              Our digital marketers use advanced analytics, including Google Analytics, to create custom reports and dashboards, providing valuable insights for informed decision-making and strategy improvement.
                          </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    </section>
    <section className="full_width pricing_sec digital_mark_pricing fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2>Unlock Your Digital Marketing Potential <br /> <span>Quality Solutions, Competitive Pricing</span> </h2>  
                <hr className="hr_black_bg" />                           
            </div>

            <div className="row">
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon"><img src="images/volume-control1.png" alt="" /></div>
                        <div className="prcng_sec_item_text">
                            <h3>Junior </h3>
                            <h4>0-3 years of experience</h4>
                            <h2><sup>$</sup>5 - <sup>$</sup>20</h2>
                            <span>Per Hour</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon"><img src="images/volume-control2.png" alt="" /></div>
                        <div className="prcng_sec_item_text">
                            <h3>Mid-Level</h3>
                            <h4>3-7 years of experience</h4>
                            <h2><sup>$</sup>15 - <sup>$</sup>25</h2>
                            <span>Per Hour</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                    <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon"><img src="images/volume-control3.png" alt="" /></div>
                        <div className="prcng_sec_item_text">
                            <h3>Senior</h3>
                            <h4>7+ years of experience</h4>
                            <h2><sup>$</sup>20 - <sup>$</sup>40</h2>
                            <span>Per Hour</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="price_content"><span>**</span>Up to 66% More Cost Savings Than the Rates in the USA and Canada </div>
        </div>
    </section>
    <DevProfiles varientclassName={"full_width testters_profile_bg digital_mark_profile fix_padding"} heading={["Explore the Profiles of Our ",<span>Expert Digital Marketers</span>, " Across Diverse Domain"]} priCTALink={"/schedule-a-call"} priCTATitle={"Secure Top Talent"} />
    <section className="full_width Hiring_Genius remote_dvloper remote_tester dm_hire_staff fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Hiring Our Top <br /><span>Remote Developers</span> Made Easy</h2>
              <hr />
          </div>
          <div className="row">
              <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_left">
                  <ul>
                      <li> 
                          <h2>Share your skill requirements</h2>
                          <p>Once you provide us with your requirements, we will schedule a call to gain a better understanding of your needs.</p>
                      </li>
                      <li> 
                          <h2>Our Expert Algorithm and HR Selection Process</h2>
                          <p>Get access to a display of pre-vetted candidates on a personalized dashboard. </p>
                      </li>
                  </ul>
              </div>
              <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4  Hrng_Gns_filter"><img src="https://www.sourcedesk.io/public/images/dm_hire_staff_img.png" alt="Search Engine Optimization Services" /></div>
              <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_right">
                  <ul>
                      <li> 
                          <h2>Schedule Interviews with Ease</h2>
                          <p>Our dedicated Account Manager will assist you in scheduling interviews with candidates of your choice. </p>
                      </li>
                      <li> 
                          <h2>Begin Your Risk-Free Trial</h2>
                          <p>We provide a 2-week risk-free trial period, enabling you to collaborate with the professional and evaluate their skills and compatibility. </p>
                      </li>                                                          
                  </ul>
              </div>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
              <NavLink to="/schedule-a-call" className="cmn_btn_fill">Invest in Your Success</NavLink>
          </div>
      </div>
  </section>
  <section className="full_width vrtul_asstnt_devloper acc_finance_hire digital_mark_hire fix_padding">
      <div className="container">
          <div className="three_tab_top text_controller">
              <h2>Hire Our Specialists by <span>Role</span> </h2>  
              <hr />
          </div>
          <div className="sdg_devlopers_technologies">
              <div>
                  <h2>Digital Marketing</h2>
                  <ul>
                      <li><h3>SEM &amp; PPC Expert</h3></li>
                      <li><h3>SEO Expert</h3></li>
                      <li><h3>Programmatic Ad Manager</h3></li>
                      <li><h3>Content Writer</h3></li>
                      <li><h3>Social Media Manager</h3></li>
                      <li><h3>Email Marketing Expert</h3></li>
                      <li><h3>Video Marketing Expert</h3></li>
                      <li><h3>ASO Expert</h3></li>
                  </ul>                
              </div>
          </div>
      </div>    
  </section>
  </>
}