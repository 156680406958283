import { NavLink } from "react-router-dom";

export default function BannerForm({bannerTitle="", bannerParagraph="", primaryLinkTitle="", primaryLink="", secondaryLinkTitle = "", secondaryLink = "",bgImg="", bannerRightImg="",bannerRightImgAlt="",primaryLinkInternal = false, secondaryLinkInternl = false,bgColor="",bgRepeat=""}) {
  return (
    <section className="full_width Home_banner inner_banner apply_talent_banner react_banner">
      <div className="Home_banner_sub hide-bg-early" style={{
            backgroundImage: `url(${bgImg})`,
            backgroundColor: bgColor,
            backgroundRepeat: bgRepeat,
          }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6 Hom_bnr_left inner_bnr_left aiml_form_sec text-center text-md-start">
              <div className="banner_text">
                <h1>{bannerTitle}</h1>
                <form
                  action="https://www.sourcedesk.io/ai-ml-development-query-submit"
                  method="post"
                >
                  <input
                    type="hidden"
                    name="_token"
                    value="z3k5OJXgRd29FLwQqvqPqdHEdFrsMHAf8YtMaCH3"
                  />{" "}
                  <div className="aiml_form d-block d-md-none my-4 my-md-0">
                    <h2>
                      <span>Request a</span>Free Quote Today!
                    </h2>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          id="banner-form__name"
                          pattern="[a-zA-Z\s]*"
                          title="Please use only alphabets"
                          required=""
                          autocomplete="off"
                        />
                      </div>

                      <div className="col-12">
                        <input
                          type="email"
                          placeholder="Email Id"
                          name="email"
                          id="banner-form__email"
                          required=""
                          autocomplete="off"
                        />
                      </div>

                      <div className="col-12">
                        <input
                          type="tel"
                          placeholder="Phone No."
                          name="moblie"
                          id="banner-form__mobile"
                          maxlength="10"
                          minlength="10"
                          pattern="^[0-9]+$"
                          title="Please use only numbers"
                          required=""
                          autocomplete="off"
                        />
                      </div>

                      <div className="col-12">
                        <textarea
                          placeholder="Description"
                          required=""
                          name="description"
                          id="banner-form__description"
                        ></textarea>
                        <input
                          type="hidden"
                          name="site_url"
                          value="https://www.sourcedesk.io/ai-ml-development"
                        />
                      </div>

                      <div className="col-12">
                        <input type="submit" value="Submit" />
                      </div>
                    </div>
                  </div>
                </form>
{bannerParagraph === "" ? false : <p>{bannerParagraph}</p>}
              </div>
              <div className="cmn_btn_grp justify-content-center justify-content-md-start">
              {primaryLink === "" || primaryLinkTitle === "" ? (
                    false
                  ) : (primaryLinkInternal ?<a href={primaryLink} className="cmn_btn_fill">
                    {primaryLinkTitle}
                  </a>:
                  <NavLink to={primaryLink} className="cmn_btn_fill">
                    {primaryLinkTitle}
                  </NavLink>
                  )}                  
                  {secondaryLink === "" || secondaryLinkTitle === "" ? (
                    false
                  ) : (
                    secondaryLinkInternl?<a href={secondaryLink} className="cmn_btn_stroke">
                    {secondaryLinkTitle}
                  </a>:
                    <NavLink to={secondaryLink} className="cmn_btn_stroke">
                      {secondaryLinkTitle}
                    </NavLink>
                  )}
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 inner_bnr_right d-none d-lg-block">
              <img
                src={`${bannerRightImg}`}
                alt={bannerRightImgAlt}
              />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-5 mt-md-0 d-none d-md-block">
              <form
                action="https://www.sourcedesk.io/ai-ml-development-query-submit"
                method="post"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="z3k5OJXgRd29FLwQqvqPqdHEdFrsMHAf8YtMaCH3"
                />{" "}
                <div className="aiml_form">
                  <h2>
                    <span>Request a</span>Free Quote Today!
                  </h2>
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        id="banner-form__name"
                        pattern="[a-zA-Z\s]*"
                        title="Please use only alphabets"
                        required=""
                        autocomplete="off"
                      />
                    </div>

                    <div className="col-12">
                      <input
                        type="email"
                        placeholder="Email Id"
                        name="email"
                        id="banner-form__email"
                        required=""
                        autocomplete="off"
                      />
                    </div>

                    <div className="col-12">
                      <input
                        type="tel"
                        placeholder="Phone No."
                        name="mobile"
                        id="banner-form__mobile"
                        maxlength="10"
                        minlength="10"
                        pattern="^[0-9]+$"
                        title="Please use only numbers"
                        required=""
                        autocomplete="off"
                      />
                    </div>

                    <div className="col-12">
                      <textarea
                        placeholder="Description"
                        name="description"
                        id="banner-form__description"
                        required=""
                      ></textarea>
                      <input
                        type="hidden"
                        name="site_url"
                        value="https://www.sourcedesk.io/ai-ml-development"
                      />
                    </div>

                    <div className="col-12">
                      <input type="submit" value="Submit" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
