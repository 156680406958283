import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function SiteMap() {
  return <><Helmet>
    <title>Sitemap</title></Helmet><div className="container mt-5 pt-5">
    <div className="row pt-5">
      <div className="col">
        <h1>Routes available</h1>
        <ul>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/sitemap">Sitemap</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/">Home</NavLink></li>
          <li><span className="text-danger">Status: WO</span>&nbsp;&nbsp;<NavLink to="/about-us">About Us</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/distinctvettingprocess">Distinct Vetted</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/react-js">React Js</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/blog">Blog</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/blog-detail">Blog Details page</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/events">Events</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/event-detail">Event Details page</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/our-team">Our Team</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/customer-review">Customer Review</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/developers">Developers</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/digital-marketers">Digital Marketers</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/accountants-finance">Accountants Finance</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/virtual-assistant">Virtual Assistant</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/qa-tester">QA Tester</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/ai-ml">AI/ML</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/ai-ml-development">AI/ML Development</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/hire-ai-ml-developer">Hire AI/ML Developer</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/apply-as-talent">Apply for Jobs</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/schedule-a-call">Schedule a Call</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/contact-us">Contact us</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/terms-service">Terms of Service</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/hire-development-team">Hire Development Team</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/schedule-a-call">Book a Call</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/job-description">Job Description Example</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/employer-referral">Employer Referral</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/resume-guide">Resume Guide</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/social-responsibility">Social Responsibility</NavLink></li>
          <li><span className="text-success">Status: OK</span>&nbsp;&nbsp;<NavLink to="/career">Career</NavLink></li>
          <li><span className="text-danger">Status: WO</span>&nbsp;&nbsp;<NavLink to="/custom-software-development">Custom Software Development</NavLink></li>
        </ul>
      </div>
    </div>
  </div></>
}