import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import LocationMap from "../Components/LocationMap";
import { Helmet } from "react-helmet";

export default function ContactUs() {
  return <>
  <Helmet>
  <title>Hire a Talent or Become a Talent - Contact Sourcedesk</title>
    <meta name="description" content="Connect with Sourcedesk: Hire talent or become one. Supercharge your career by joining our network. Explore opportunities or find exceptional talent. Contact us now." />
    {/* <!--  Head   -->
    <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />

    {/* <!-- Head end -->  */}
 {/* <script src='https://www.google.com/recaptcha/api.js' async defer></script> */}
  </Helmet>
  <InnerBanner
  InnBanner="url('images/contact_banner.png')"
  bgRepeat="no-repeat"
  bgColor="#fafbfd"
  BnrRightImg="images/contact_top_img.png"
  TitleInn={["We Are Here for You"]}
  ParaInn="We would love to hear from you! Feel free to contact us with any inquiries or to learn more about our services."
  priCTALink="#contact-frm" priCTATitle="Send Us a Message" secCTALink={""} secCTATitle={""} priLinkInternal={true}
/>
<section className="full_width contact_frm fix_padding" id="contact-frm">
    <div className="container">
        <div className="row g-0">
            <div className="col-12 col-md-6 col-xl-6 col-lg-6 sdg_lcnts">
                <div className="text_controller">
                    <h2>SDG Locations</h2>
                    <hr />
                    <p>Have any questions? We'd love to hear from you.</p>
                </div>

                {/* <div className="footer_sub_map">
                    <img src="https://www.sourcedesk.io/public/images/world_map.png" alt="Location Map"/>
                    <div id="horizontalTab_cnt" style={{display: "block", width: "100%", margin: "0px"}}>
                            <ul className="resp-tabs-list">
                                <li className="resp-tab-item resp-tab-active" aria-controls="tab_item-0" role="tab"></li>
                                <li className="resp-tab-item" aria-controls="tab_item-1" role="tab"></li>
                                <li className="resp-tab-item" aria-controls="tab_item-2" role="tab"></li>
                                <li className="resp-tab-item" aria-controls="tab_item-3" role="tab"></li>
                            </ul>

                        <div className="resp-tabs-container">
                            <h2 className="resp-accordion resp-tab-active" role="tab" aria-controls="tab_item-0"><span className="resp-arrow"></span></h2><div className="resp-tab-content resp-tab-content-active" style={{display:"block"}} aria-labelledby="tab_item-0">
                                <div className="footer_sub_loaction ">  
                                <h2>Canada</h2>
                                <h3>Toronto</h3>
                                <p>5399 Eglinton Ave. <br/> West Suite 212,<br/> Toronto, ON, M9C 5K6</p>
                                </div>
                            </div>
                            <h2 className="resp-accordion" role="tab" aria-controls="tab_item-1"><span className="resp-arrow"></span></h2><div className="resp-tab-content" aria-labelledby="tab_item-1">
                                <div className="footer_sub_loaction ">
                                    <h2>Colombia</h2>
                                    <h3>Medellin</h3>
                                    <p>Cra. 42 #3 Sur 81 Torre  1 Piso 15,<br/> El Poblado,<br/> Medellín, Antioquia, Colombia</p>
                                </div>
                            </div>
                            <h2 className="resp-accordion" role="tab" aria-controls="tab_item-2"><span className="resp-arrow"></span></h2><div className="resp-tab-content" aria-labelledby="tab_item-2">
                                <div className="footer_sub_loaction">
                                    <h2>India</h2>
                                    <h3>Kolkata</h3>
                                    <p>Shaila Tower, EP-204, <br/> Salt Lake, Sector-V,<br/> Kolkata - 700091 <br/>
                                    info@sourcedeskglobal.com</p>
                                </div>
                            </div>
                            <h2 className="resp-accordion" role="tab" aria-controls="tab_item-3"><span className="resp-arrow"></span></h2><div className="resp-tab-content" aria-labelledby="tab_item-3">
                                <div className="footer_sub_loaction">
                                    <h2>USA</h2>
                                    <h3>New York</h3>
                                    <p>One Liberty Plaza, <br/> 165 Broadway,<br/>  Suite 2301,  New York, 10006</p>
                                </div>
                            </div>
                        </div>
                        <div className="cmn_btn_grp">
                            <NavLink to="/" className="cmn_btn_fill" onClick={(e)=>e.preventDefault()}>Get in Touch</NavLink>
                        </div>
                    </div>
                </div> */}
                <LocationMap />
                <div className="cmn_btn_grp">
                            <NavLink to="/" className="cmn_btn_fill" onClick={(e)=>e.preventDefault()}>Get in Touch</NavLink>
                        </div>

                
            </div>

            <div className="col-12 col-md-6 col-xl-6 col-lg-6 getINtouch">
                
                        <div className="text_controller">
                <h2>Get In Touch</h2>
                <hr />
            </div>
            <div className="row cntct_items">
              {/* These attributes were inside the below form element */}
              {/* id="webform6057464000001322004" action="https://crm.zoho.com/crm/WebToLeadForm" name="WebToLeads6057464000001322004" method="POST" onsubmit="javascript:document.charset=&quot;UTF-8&quot;; return checkMandatory6057464000001322004()" accept-charset="UTF-8" */}
                <form >
                    {/* <input type="hidden" name="_token" value="PlsHvbxuFkI5qamIAga5hIhmk0aeue8QIx8UbduA"/>
                     <input type="text" style="display:none;" name="xnQsjsdp" value="759fa17f25ccd3819f9921712f96f31bcb32d7f9ec070e1721a90c7145bd6ec6"/> 
                     <input type="hidden" name="zc_gad" id="zc_gad" value=""/>
                     <input type="text" style="display:none;" name="xmIwtLD" value="9044406df3d1cc75f2ef8df995bb4b3bcfe720b973ab75535170d1e9b0774c6bf52a9d444848b192e60115c113e9000c">/ 
                     <input type="text" style="display:none;" name="actionType" value="TGVhZHM="/>
                     <input type="text" style="display:none;" name="returnURL" value="https://www.sourcedesk.io/thank-you?thank-you=3"/>  */}
                          {/* Do not remove this code. */}

                    <div className="row">
                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <label>First Name<span>*</span></label>
                        <input type="text" id="First_Name" name="First Name" pattern="[a-zA-Z\s]*" title="Please use only alphabets" placeholder="First Name" autocomplete="off" required="" />
                        <small className="text-danger" id="name_error"> </small>
                    </div>
                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <label>Last Name<span>*</span></label>
                        <input type="text" id="Last_Name" name="Last Name" pattern="[a-zA-Z\s]*" title="Please use only alphabets" placeholder="Last Name" autocomplete="off" required="" />
                        <small className="text-danger" id="name_error"> </small>
                    </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <label>Phone<span>*</span></label>
                        <input type="tel" id="Phone" name="Phone" placeholder="Phone" maxlength="10" minlength="10" pattern="^[0-9]+$" title="Please use only numbers" autocomplete="off" required="" />
                        <small className="text-danger" id="email_error"></small>
                    </div>

                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <label>Email<span>*</span></label>
                        <input type="email" id="Email" name="Email" autocomplete="off" placeholder="Email" required="" />
                        <small className="text-danger" id="email_error"></small>
                    </div>
                    
                </div>
                <br />
                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <label>Your Inquiry<span>*</span></label>
                        <select id="LEADCF3" name="LEADCF3" required="">
                            <option value="">Select</option>
                                                        <option value="Hire Developer/Software Engineers"> Hire Developer/Software Engineers </option>
                                                        <option value="Apply For jobs"> Apply For jobs </option>
                                                        <option value="General Questions"> General Questions </option>
                                                        <option value="Investors"> Investors </option>
                                                        <option value="Media &amp; Press"> Media &amp; Press </option>
                                                        <option value="Others"> Others </option>
                                                    </select>
                        <small className="text-danger" id="inquiry_error"> </small>
                    </div>
                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <label>How can we help you?<span>*</span></label>
                        <textarea placeholder="Please write down your query" id="Description" name="Description" required=""></textarea>
                    </div>

                     {/* <div className='zcwf_row'> <div className='zcwf_col_lab'></div><div className='zcwf_col_fld'><div className='g-recaptcha' data-sitekey='6LdCTPYpAAAAABImvms4zWqI1QRxClA22ay4Crh_' data-theme='light' data-callback='rccallback6057464000001322004' captcha-verified='false' id='recap6057464000001322004'></div><div  id='recapErr6057464000001322004' style='font-size:12px;color:red;visibility:hidden;'>Captcha validation failed. If you are not a robot then please try again.</div></div></div>  */}
                    <input type="submit" value="Send Message"/>

                    <div style={{display: "none"}}>
                         <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="LEADCF1">Enquiry Type</label></div><div className="zcwf_col_fld"><select className="zcwf_col_fld_slt" id="LEADCF1" name="LEADCF1">
                          <option value="-None-">-None-</option>
                          <option value="VBK Sales Flow">VBK Sales Flow</option>
                          <option value="SEO-PPC-Website Development Flow">SEO-PPC-Website Development Flow</option>
                          <option value="CDAP Sales Flow">CDAP Sales Flow</option>
                          <option selected="" value="SourceDeskio Leads">SourceDeskio Leads</option>
                          </select><div className="zcwf_col_help"></div></div></div>

                          <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="Lead_Source">Lead Source</label></div><div className="zcwf_col_fld"><select className="zcwf_col_fld_slt" id="Lead_Source" name="Lead Source">
                          <option value="-None-">-None-</option>
                          <option selected="" value="Website">Website</option>
                          <option value="Google Search">Google Search</option>
                          <option value="Chat">Chat</option>
                          <option value="Word of Mouth">Word of Mouth</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Instagram">Instagram</option>
                          <option value="Inbound Email">Inbound Email</option>
                          <option value="Inbound Call">Inbound Call</option>
                        </select><div className="zcwf_col_help"></div></div></div>

                        <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="Lead_Status">Lead Status</label></div><div className="zcwf_col_fld"><select className="zcwf_col_fld_slt" id="Lead_Status" name="Lead Status">
                          <option value="-None-">-None-</option>
                          <option selected="" value="Not Contacted">Not Contacted</option>
                          <option value="Contacted">Contacted</option>
                          <option value="Meeting Scheduled">Meeting Scheduled</option>
                          <option value="Interested">Interested</option>
                          <option value="Junk Lead">Junk Lead</option>
                          <option value="Lost Lead">Lost Lead</option>
                          <option value="Pre-Qualified">Pre-Qualified</option>
                        </select><div className="zcwf_col_help"></div></div></div>

                        <div className="zcwf_row wfrm_fld_dpNn"><div className="zcwf_col_lab" style={{fontSize:"12px", fontFamily: "Arial"}}><label for="LEADCF17">Submitted from page</label></div><div className="zcwf_col_fld"><input type="text" id="LEADCF17" name="LEADCF17" maxlength="255" value="https://www.sourcedesk.io/contact-us" /></div></div></div>

                    </form></div>

                    {/* <script>
    function validateEmail6057464000001322004()
    {
        var form = document.forms['WebToLeads6057464000001322004'];
        var emailFld = form.querySelectorAll('[ftype=email]');
        var i;
        for (i = 0; i < emailFld.length; i++)
        {
            var emailVal = emailFld[i].value;
            if((emailVal.replace(/^\s+|\s+$/g, '')).length!=0 )
            {
                var atpos=emailVal.indexOf('@');
                var dotpos=emailVal.lastIndexOf('.');
                if (atpos<1 || dotpos<atpos+2 || dotpos+2>=emailVal.length)
                {
                    alert('Please enter a valid email address. ');
                    emailFld[i].focus();
                    return false;
                }
            }
        }
        return true;
    }

      function checkMandatory6057464000001322004() {
        var mndFileds = new Array('First Name','Last Name','Phone','Email','Description','LEADCF3');
        var fldLangVal = new Array('First\x20Name','Last\x20Name','Phone','Email','Message','Subject');
        for(i=0;i<mndFileds.length;i++) {
          var fieldObj=document.forms['WebToLeads6057464000001322004'][mndFileds[i]];
          if(fieldObj) {
            if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
             if(fieldObj.type =='file')
                { 
                 alert('Please select a file to upload.'); 
                 fieldObj.focus(); 
                 return false;
                } 
            alert(fldLangVal[i] +' cannot be empty.'); 
              fieldObj.focus();
              return false;
            }  else if(fieldObj.nodeName=='SELECT') {
             if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
                alert(fldLangVal[i] +' cannot be none.'); 
                fieldObj.focus();
                return false;
               }
            } else if(fieldObj.type =='checkbox'){
             if(fieldObj.checked == false){
                alert('Please accept  '+fldLangVal[i]);
                fieldObj.focus();
                return false;
               } 
             } 
             try {
                 if(fieldObj.name == 'Last Name') {
                name = fieldObj.value;
                }
            } catch (e) {}
            }
        }
        if(!validateEmail6057464000001322004()){return false;}

        var phone_no= $("#Phone").val();
        if(isNaN(phone_no) || phone_no.length!=10){
        alert('Please enter a valid phone number. ');
        return false;
        }
        
    var urlparams = new URLSearchParams( window.location.search);
    if(urlparams.has('service') && (urlparams.get('service')==='smarturl')){
        var webform = document.getElementById('webform6057464000001322004');
         var service =  urlparams.get('service'); 
        var smarturlfield = document.createElement('input');
        smarturlfield.setAttribute('type','hidden');
        smarturlfield.setAttribute('value',service);
        smarturlfield.setAttribute('name','service');
        webform.appendChild(smarturlfield); 
    }
document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
    }

function tooltipShow6057464000001322004(el){
    var tooltip = el.nextElementSibling;
    var tooltipDisplay = tooltip.style.display;
    if(tooltipDisplay == 'none'){
        var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
        for(i=0; i<allTooltip.length; i++){
            allTooltip[i].style.display='none';
        }
        tooltip.style.display = 'block';
    }else{
        tooltip.style.display='none';
    }
}
</script> */}
                
            </div>
        </div>
    </div>
</section>
<section className="full_width  foot_gap schedule_call fix_padding">
    <div className="container">
        <div className="text_controller text_controller_center">
            <h2>Schedule a <br /><span>Discovery Call</span></h2>
        </div>

        <div className="row justify-content-center text-center">
            <div className="col-md-6 col-xl-5">
                <div className="call_box">
                    <h3>contact sales</h3>
                    <a href="tel:+18883734333">1-888-373-4333</a>
                </div>
            </div>

            <div className="col-md-6 col-xl-5">
                <div className="call_box">
                    <h3>Send an email</h3>
                    <a href="mailto:sales@sourcedeskglobal.com">sales@sourcedeskglobal.com</a>
                </div>
            </div>
        </div>
    </div>
</section></>
}