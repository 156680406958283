import React from 'react';

function TalentParameter() {
  return (
    <>
        <section className="full_width Human_img fix_padding border_top">
            <div className="container">
                <div className="text_controller">
                    <h2><span> Pre-Vetted and Qualified IT Talent Network</span> <br /> Parameters for Quality Assurance</h2>
                    <hr />
                </div>

                <div className="row Human_img_bottom">
                    <div className="col-12 col-md-7 col-sm-7 col-xl-7 Hmn_img_bt_left">
                        <ul>
                            <li>
                                <div className="Hmn_bt_left_border"></div>
                                <div className="Hmn_bt_left_text">Excellent Communication</div>
                            </li>
                            <li>
                                <div className="Hmn_bt_left_border"></div>
                                <div className="Hmn_bt_left_text">Technical Expertise</div>
                            </li>
                            <li>
                                <div className="Hmn_bt_left_border"></div>
                                <div className="Hmn_bt_left_text">Cultural Fit</div>
                            </li>
                            <li>
                                <div className="Hmn_bt_left_border"></div>
                                <div className="Hmn_bt_left_text">Problem-Solving Abilities</div>
                            </li>
                            <li>
                                <div className="Hmn_bt_left_border"></div>
                                <div className="Hmn_bt_left_text">Self-Discipline and Time Management Skills</div>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-5 col-sm-5 col-xl-5 Hmn_img_bt_right"><img src="images/Human_img.png" alt="images" /></div>
                </div>
            </div>
        </section>
    </>
  )
}

export default TalentParameter;