export default function Platforms() {
  return (
    <section className="full_width react_expertise fix_padding">
      <div className="container">
        <div className="text_controller text_controller_center">
          <h2>
            Unleash the Technical Prowess of Our <span> ReactJS Developer</span>{" "}
            Pool
          </h2>
          <hr />
        </div>

        <ul className="uniq_infrmtn_down">
          <li>
            <div className="uniq_infrmtn_left">Libraries </div>
            <div className="uniq_infrmtn_right">
              React Bootstrap | Material UI | Ant Design | React Router | Rebass
              | Semantic UI React | Redux | React Motion | React Suite | Shards
              React | React 360 | React-Intl
            </div>
          </li>

          <li>
            <div className="uniq_infrmtn_left">Frameworks </div>
            <div className="uniq_infrmtn_right">
              Material Kit | Create React App | Shards React | Styled Components
              | Grommet | React DND | Viro React
            </div>
          </li>

          <li>
            <div className="uniq_infrmtn_left">APIs</div>
            <div className="uniq_infrmtn_right">
              Facebook API | Instagram API | YouTube API | Spotify API | Apple
              Music API | Google API | Jira REST API | GitHub API | SoundCloud
              API
            </div>
          </li>

          <li>
            <div className="uniq_infrmtn_left">Platforms</div>
            <div className="uniq_infrmtn_right">
              Amazon Web Services (AWS) | Google Cloud Platform (GCP) | Linux |
              Docker | Heroku | Firebase | Digital Ocean | Oracle | Kubernetes |
              Dapr | Azure | AWS Lambda | Redux
            </div>
          </li>

          <li>
            <div className="uniq_infrmtn_left">Databases </div>
            <div className="uniq_infrmtn_right">
              MongoDB | PostgreSQL | MySQL | Redis | SQLite | MariaDB |
              Microsoft SQL Server
            </div>
          </li>
        </ul>

        <div className="cmn_btn_grp cmn_btn_grp_center">
          <a
            href="https://www.sourcedesk.io/schedule-a-call"
            className="cmn_btn_fill"
          >
            Hire ReactJS Developers Now
          </a>
        </div>
      </div>
    </section>
  );
}
