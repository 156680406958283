import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import { Helmet } from "react-helmet";

export default function EmployerReferral() {
  return <><InnerBanner
  InnBanner="url('images/employer_ref_banner.png')"
  bgRepeat="no-repeat"
  bgColor="#fafbfd"
  BnrRightImg="images/employer_ref_right_img.png"
  TitleInn={["Join the ",<span>Sourcedesk Global Referral Program!</span>]}
  ParaInn="Refer Businesses to Sourcedesk Global’s Talent Solutions and Earn a $5,000 Reward"
  priCTALink="/schedule-a-call" priCTATitle="Explore More" secCTALink={""} secCTATitle={""}
/>
<Helmet>
<title>Dedicated Hiring Resource - Apply-as-talent</title>
{/* 
<!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />
</Helmet>
<section className="full_width Hiring_Genius referral_program remote_dvloper specialist_SEO_Expert dm_hire_staff employer_ref_prog fix_padding">
    <div className="container">
        <div className="text_controller text_controller_center">
            <h2>An Overview of Our<span> Referral Program</span></h2>
            <hr />
            <p>Expand our team and reap the benefits. Refer potential employers to us today!</p>
        </div>
        <div className="row">
            <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_left">
                <ul>
                    <li>
                        <p>Refer other businesses to us at <br /><a href="mailto:referral@sdg.com">referral@sdg.com</a></p>
                    </li>
                    <li>
                        <p>They choose the right <br /> candidates for the job</p>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4  Hrng_Gns_filter"><img src="images/employer_ref_mid.png" alt=""/></div>
            <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_right">
                <ul>
                    <li>
                        <p>You receive a $5,000 reward</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section className="full_width emp_ref_benefit fix_padding">
    <div className="container">
        <div className="text_controller text_controller_center">
            <h2>Benefits for us, Reward for you</h2>
            <hr />
        </div>
        <div className="row Human_img_bottom">
            <div className="col-12 col-md-7 col-sm-7 col-xl-7 Hmn_img_bt_left">
                <ul>
                    <li>
                        <div className="Hmn_bt_left_border"></div>
                        <div className="Hmn_bt_left_text">Introduce businesses by connecting them with your Sourcedesk Global Account Manager, Sourcedesk Global Representative, or by email at <a href="mailto:referral@sdg.com">referral@sdg.com</a></div>
                    </li>
                    <li>
                        <div className="Hmn_bt_left_border"></div>
                        <div className="Hmn_bt_left_text">Once the referred business hires from Sourcedesk Global and becomes a paying client, you receive a flat $5,000 reward.</div>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-5 col-sm-5 col-xl-5 Hmn_img_bt_right"><img src="images/employer_ref_benefit.png" alt="" /></div>
        </div>
    </div>
</section>
<section className="full_width vettng_video_black foot_gap sdg_advntg_video_black  ">
    <div className="container text_controller text_controller_center">
        <h2> <span>Embark on the Path of Discovery </span> <br /> Connect with Top-Tier Professionals </h2>
        <div className="cmn_btn_grp "> <NavLink to="/pricing" className="cmn_btn_fill">Explore Our Straightforward Pricing Model</NavLink> <NavLink to="/schedule-a-call" className="cmn_btn_stroke">Foster Engagement with Experts</NavLink> </div>
    </div>
</section></>
}