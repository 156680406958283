import Accordion from "react-bootstrap/Accordion"; 
import InnerBanner from "../Components/InnerBanner"; 
import TrustedSlider from "../Components/TrustedSlider"; 
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
export default function HowItWorks() { 
  return <>
  <Helmet>
  <title>Build Your Team Efficiently. Find Out How it Works</title>
<meta name="description" content="Build your dream team efficiently with Sourcedesk. Discover how our process works for seamless collaboration and success. Elevate your hiring strategy now." />
<meta name="title" content="Build Your Team Efficiently. Find Out How it Works" />
    {/* <!-- Canonical & hrefLang --> */}
    <link rel="canonical" href="https://www.sourcedesk.io/how-it-works" />
    <link rel="alternate" hrefLang="x-default" href="https://www.sourcedesk.io/how-it-works" />
    <link rel="alternate" hrefLang="en-US" href="https://www.sourcedesk.io/how-it-works" />

    {/* <!-- Twitter Card meta tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@sourcedeskgl" />
    <meta name="twitter:title" content="Build Your Team Efficiently. Find Out How it Works" />
    <meta name="twitter:description" content="Build your dream team efficiently with Sourcedesk. Discover how our process works for seamless collaboration and success. Elevate your hiring strategy now." />
    <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />
    {/* <!-- Facebook Open Graph tags --> */}
    <meta property="og:url" content="https://www.sourcedesk.io/how-it-works" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Build Your Team Efficiently. Find Out How it Works" />
    <meta property="og:description" content="Build your dream team efficiently with Sourcedesk. Discover how our process works for seamless collaboration and success. Elevate your hiring strategy now." />
    <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />
    {/* <!-- Additional Open Graph tags for better customization --> */}
    <meta property="og:image:width" content="2400" />
    <meta property="og:image:height" content="1260" />
    <meta property="og:image:alt" content="SourceDesk Global" />
    <meta property="og:locale" content="en_US" />
{/* <!----  Head  --> */}
{/* <!-- Google Tag Manager --> */}
{/* <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script> */}
{/* <!-- End Google Tag Manager --> */}
{/* <!-- Required meta tags --> */}
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw " />
<meta name="robots" content="index,follow" />

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />


{/* <!-- Head end --> */}
<script type="application/ld+json">{`
{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "@id": "https://www.sourcedesk.io/#breadcrumb",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Why SDG",
      "item": "javascript:void(0)"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Virtual Assistant",
      "item": "https://www.sourcedesk.io/how-it-works"
    }
  ]
}
`}</script>
<script type="application/ld+json">{`
{
  "@context": "http://schema.org",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "SourceDesk",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    }
  },
  "author": {
    "@type": "Person",
    "name": "SourceDesk"
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4.5",
    "bestRating": "5",
    "worstRating": "1"
  },
  "reviewBody": "Review Body"
}
`}</script>
  </Helmet>
  <InnerBanner InnBanner="url(images/banner-how-it-works.jpg)" bgColor="#fafbfd" bgRepeat="no-repeat" BnrRightImg="images/inr_bnr_img1.png" TitleInn={[ <span>Revolutionize Your Hiring Process with SDG</span>, " Build a High-Efficiency Tech Team", ]} ParaInn="" priCTALink={"#stepping-stones"} priCTATitle={"Get Started"} secCTALink={""} secCTATitle={""} priLinkInternal={true} />
      <TrustedSlider defaultTitle="Trusted By" />
      <section className="full_width stepping_stones fix_padding" id="stepping-stones">
          <div className="container">
              <div className="text_controller text_controller_center">
                  <h2>The Roadmap to Intelligent Hiring</h2>
                  <hr />
              </div>
              <div className="row">
                  <div className="col-12 col-md-5 col-sm-5 col-xl-5 We_hrns_image"> <img src="images/how__it_works_img1.png" alt="Hiring Roadmaps" /> </div>
                  <div className="col-12 col-md-7 col-sm-12 col-xl-7 We_hrns_usp">
                      <Accordion defaultActiveKey="0" className="stppng_accordian">
                          <Accordion.Item eventKey="0">
                              <Accordion.Header>Advanced Screening</Accordion.Header>
                              <Accordion.Body> Our rigorous candidate selection process includes Profile Screening, Language Proficiency Assessment, Aptitude Evaluation, Technical Assessment, and Panel Interviews. We enhance pre-vetting with AI and HR solutions, optimizing skills and cultural fit evaluation through AI-driven video interviews and gaming assessments. We aim to reduce bias and promote diversity by blending AI and human intelligence to meet your sourcing needs. </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                              <Accordion.Header>Specification Sharing</Accordion.Header>
                              <Accordion.Body> We prioritize hiring the right professionals. Please provide comprehensive information, including the job description, for precise matching with our AI and ML algorithms. Our Account Manager schedules a call to guide and assist through the entire process. </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                              <Accordion.Header>Matching &amp; Shortlisting</Accordion.Header>
                              <Accordion.Body> Our AI platform quickly identifies ideal candidates. You access profiles, rates, and availability.Our dedicated Account Manager is actively involved in the matching and shortlisting process, providing expert guidance and support. </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                              <Accordion.Header>Interviewing</Accordion.Header>
                              <Accordion.Body> After selecting your candidate, our platform schedules interviews based on availability. Our dedicated team is ready to assist during interviews, should you require it. </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                              <Accordion.Header>Hiring, Onboarding &amp; Managing</Accordion.Header>
                              <Accordion.Body> After choosing your preferred candidate, SDG assists with salary and budget negotiations. Our platform handles contract management, legalities, and onboarding. A talent success coach maintains client-talent relationships and oversees HR, payroll, performance, retention, attendance, leave, succession planning, and medical benefits. </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>
                  </div>
                  <div className="row text-center"><span>Embark on a journey of intelligent hiring with SDG, marked by efficiency, precision, and excellence.</span></div>
                  <div className="cmn_btn_grp cmn_btn_grp_center"><NavLink to="/schedule-a-call" className="cmn_btn_fill">
                          <h4 style={{fontSize: "14px",margin: "0"}}>Unlock Your Hiring Potential</h4>
                      </NavLink></div>
              </div>
          </div>
      </section>
      <section className="full_width how_wrks_pgbk fix_padding">
          <div className="container">
              <div className="text_controller">
                  <h2>Benefits of Hiring Resources Through <br /> <span> SDG Outsourcing </span></h2>
                  <hr className="hr_black_bg" />
              </div> <span>Choose Sourcedesk Global for outsourcing resources and enjoy a wide range of benefits, thanks to our cohesive, excellence-driven approach. Expect the following advantages:</span>
              <ul>
                  <li>
                      <h3><strong>Top Software Engineers:</strong></h3> Access top-tier, experienced software engineers at the forefront of IT. Our talent pool offers specialized expertise in various technologies and domains, ensuring exceptional project results.
                  </li>
                  <li>
                      <h3><strong>Dedicated Account Manager:</strong></h3> Get personalized support from a dedicated Account Manager who ensures smooth communication, monitors candidate performance, and meets project needs. Focus on your core business while we handle operations.
                  </li>
                  <li>
                      <h3><strong>Flexible Notice and Contract Periods:</strong></h3> Enjoy flexible notice and contract periods customized to your project's evolving needs. Optimize resource allocation and meet project milestones efficiently with our adaptable approach.
                  </li>
                  <li>
                      <h3><strong>Job Benefits &amp; Perks:</strong></h3> At SDG, we prioritize job satisfaction and retention. Our comprehensive benefits, including health insurance, retirement plans, professional development and more, create a rewarding work environment that empowers talent for project success.
                  </li>
                  <li>
                      <h3><strong>Global Availability:</strong></h3> With a global presence, SDG is equipped to meet your requirements in any time zone.Whether local or global projects, our talent network guarantees seamless fulfillment, enhancing collaboration, productivity, and efficiency across geographical boundaries.
                  </li>
                  <li>
                      <h3><strong>Risk Mitigation and Cost Savings:</strong></h3> SDG reduces project risks and saves costs through streamlined recruitment and onboarding. Our cost-effective solutions enable strategic resource allocation and budget optimization, minimizing financial risks and maximizing ROI.
                  </li>
              </ul> <span>Choose SDG for top software engineers, dedicated support, flexible terms, comprehensive benefits, global reach, and risk mitigation. Partner with us for project success.</span>
          </div>
      </section>
      <section className="full_width verses_sec fix_padding">
          <div className="container">
              <div className="text_controller text_controller_center">
                  <h2>Unlocking the Power of SDG's Top Talent:<br /><span>A Smarter Approach to Workforce Optimization </span></h2>
                  <hr />
                  <p>Choosing SDG's Top Talent offers cost savings, flexibility, and specialized skills, addressing budget constraints and skilled labour scarcity. Boost competitiveness and navigate the economic landscape confidently while eliminating overhead costs and recruitment efforts.</p>
              </div>
          </div>
      </section>
      <section className="full_width pricing_sec common_price_sec fix_padding">
          <div className="container">
              <div className="text_controller text_controller_center">
                  <h2>Transparent and Flexible Pricing</h2>
                  <hr className="hr_black_bg" />
                  <p>Sourcedesk Global (SDG) offers structured, transparent rates for hiring top talent at various experience levels, providing flexibility and affordability. Here are the details:</p>
              </div>
              <div className="row">
                  <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                      <div className="prcng_sec_item">
                          <div className="prcng_sec_item_icon"><img src="images/volume-control1.png" alt="" /></div>
                          <div className="prcng_sec_item_text">
                              <h3>Junior Level</h3>
                              <h4>0-3 years of experience</h4>
                              <h2><sup>$</sup>5 - <sup>$</sup>10</h2> <span>Per Hour</span>
                              <p>SDG offers a cost-effective solution for entry-level positions, with rates ranging from $5 to $10 per hour. We provide an efficient and affordable approach to hiring exceptional talent early in their careers. </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                      <div className="prcng_sec_item">
                          <div className="prcng_sec_item_icon"><img src="images/volume-control2.png" alt="" /></div>
                          <div className="prcng_sec_item_text">
                              <h3>Mid Level</h3>
                              <h4>3-7 years of experience</h4>
                              <h2><sup>$</sup>9 - <sup>$</sup>15</h2> <span>Per Hour</span>
                              <p>Tap into our highly-skilled and experienced talent pool. With rates ranging from $9 to $15 per hour, you can access experienced professionals with a solid career foundation.</p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                      <div className="prcng_sec_item">
                          <div className="prcng_sec_item_icon"><img src="images/volume-control3.png" alt="" /></div>
                          <div className="prcng_sec_item_text">
                              <h3>Senior Level</h3>
                              <h4>7+ years of experience</h4>
                              <h2><sup>$</sup>12 - <sup>$</sup>25</h2> <span>Per Hour</span>
                              <p>Our senior professionals offer extensive experience and a proven track record at rates from $12 to $25 per hour.</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="after_para">SDG provides transparent pricing tailored to your needs, whether for entry-level, mid-level, or senior roles, enabling informed budget optimization.</div>
              <div className="cmn_btn_grp cmn_btn_grp_center"><NavLink to="/schedule-a-call" className="cmn_btn_fill">Discover Top Talent</NavLink></div>
          </div>
      </section>
      <section className="full_width talent_sec our_success fix_padding">
          <div className="container">
              <div className="talent_sec_top">
                  <div className="tlnt_sec_top_left text_controller">
                      <h2> Achieving Client Success <br /> <span>Through Dedicated Professionals </span></h2>
                  </div>
                  <div className="tlnt_sec_top_right">
                      <p> Discover the Impact Our Experts Have Made on Clients' Businesses</p>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 our_success_text"> <img src="images/promotion.png" alt="" />
                      <h2>Sarah Davis, VP of Operations</h2>
                      <p>Sourcedesk Global's talent-hiring service has been an absolute lifesaver for our organization. Finding the right talent is always a challenge, but their team took that burden off our shoulders. They truly understood our requirements and provided us with a pool of exceptional candidates who were not only technically proficient but also a great cultural fit. Their streamlined process eliminated the countless hours we typically spend on screening and interviewing, allowing us to focus on our core business operations.</p>
                  </div>
                  <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 our_success_text"> <img src="images/promotion.png" alt="" />
                      <h2>Alex Rodriguez, Co-Founder and CTO</h2>
                      <p>As a rapidly growing startup, we needed to scale our team quickly, but the traditional hiring process was proving to be a major bottleneck. That's when we discovered Sourcedesk Global. Their team seamlessly integrated with ours, taking care of the entire recruitment process from start to finish. They provided us with top-notch developers who hit the ground running, enabling us to concentrate on executing our vision without the HR-related headaches.</p>
                  </div>
                  <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 our_success_text"> <img src="images/promotion.png" alt="" />
                      <h2>Samantha Lee, Chief People Officer</h2>
                      <p>Sourcedesk Global's resource hiring service has been a game-changer for our organization. Their comprehensive approach to understanding our business needs and culture ensured that the resources they provided were not just skilled but also aligned with our values. The entire process was smooth and efficient, saving us countless hours that we would have otherwise spent on sourcing, screening, and onboarding candidates. With their support, we were able to rapidly scale our team without compromising on quality.</p>
                  </div>
              </div>
          </div>
      </section>
</> }