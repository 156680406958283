import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function LifeAtSDG() {
  return (
    <>
      <Helmet>
        <title>Life at SDG | Sourcedesk</title>
        <meta name="description" content="Life at SDG" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw " /> */}
        {/* <meta name="robots" content="index,follow" /> */}
      </Helmet>
      <section class="full_width Home_banner inner_banner apply_talent_banner hire_banner">
        <div
          class="Home_banner_sub"
          style={{
            background: '#f9fcfc',
            height: '400px',
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12Hom_bnr_left inner_bnr_left">
                <div class="banner_text">
                  <h1 className="mb-0">
                    Life At <span>SDG</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="full_width fix_padding">
        <div class="container">
          <div class="text_controller text_controller_center">
            <h2>Life at Sourcedesk Global</h2>
            <hr />
            <p>
              Sourcedesk Global is a vibrant and dynamic organization filled with smart, passionate, and approachable professionals. Our employees are our core strength. They love to accept
              challenges, and celebrate victories together. Our supportive work culture provides great opportunities for our employees to discuss new ideas and grow as a team.
            </p>
          </div>
        </div>
      </section>
      {/* text_big_controller */}
      <section class="full_width border-top fix_padding">
        <div class="container">
          <div class="text_controller text_controller_center">
            <h2>
              Life of <span>SDG</span>
            </h2>
            <hr />
          </div>
          <div class="ft_grid">
            <ul>
              <li>
                <div class="ft_pro_box">
                  <img src="images/career_life_1.jpg" alt="img" />
                  <div class="vdo_btn">
                    <a href="/">
                      <img src="images/vdo_triangle.png" alt="Icon" />
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="ft_pro_box">
                  <img src="images/career_life_2.jpg" alt="img" />
                </div>
              </li>
              <li>
                <div class="ft_pro_box">
                  <img src="images/career_life_3.jpg" alt="img" />
                </div>
              </li>
              <li>
                <div class="ft_pro_box">
                  <img src="images/career_life_4.jpg" alt="img" />
                </div>
              </li>
              <li>
                <div class="ft_pro_box">
                  <img src="images/career_life_5.jpg" alt="img" />
                </div>
              </li>
              <li>
                <div class="ft_pro_box">
                  <img src="images/career_life_6.jpg" alt="img" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding" style={{ background: '#1b212d url(images/life-at-sdg-cta-bg-1.jpg) no-repeat 50% 50%' }}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <div className="text_controller">
              <h2 className="text-end pe-sm-3 pe-lg-4 border-right text-white">
                <span>READY TO </span>
                <br />
                LEARN MORE?
              </h2>
            </div>
            <p className="ps-sm-3 ps-lg-4 me-sm-5 text-white text-center text-sm-start">
              Talk to an expert from
              <br />
              Sourcedesk Global team.
            </p>
            <NavLink to="/" className="cmn_btn_fill text-nowrap ms-sm-5 cmn_btn_fill_white me-0">
              Apply Now
            </NavLink>
          </div>
        </div>
      </section>
      <section class="full_width We_harness appy_why_choose fix_padding" data-inviewport="scale-in">
        <div class="container">
          <div class="text_controller text_controller_center">
            <h2>
              <span>Employee Benefits</span>
            </h2>
            <hr />
          </div>

          <div class="row ">
            {/* We_harness_bottom */}
            <div class="col-12 col-md-6 col-sm-5 col-xl-5 We_hrns_image">
              <svg viewBox="0 0 610 610">
                <circle cx="306" cy="292" r="290" fill="none"></circle>
              </svg>

              <div class="We_hrns_image_grp">
                <img src="https://www.sourcedesk.io/public/images/apply_why_choose.png" alt="Flower" class="We_hrns_image_main" />
              </div>
            </div>

            <div class="col-12 col-md-6 col-sm-12 col-xl-7 We_hrns_usp">
              <ul>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon1.png" alt="Icon" />
                  </div>
                  <h4>Comprehensive Medical Benefits</h4>
                </li>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon2.png" alt="Icon" />
                  </div>
                  <h4>Performance &amp; Review Bonus Program</h4>
                </li>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon3.png" alt="Icon" />
                  </div>
                  <h4>Access to Prestigious Co-Working Spaces</h4>
                </li>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon4.png" alt="Icon" />
                  </div>
                  <h4>Daily Meals and Additional Convenience Perks</h4>
                </li>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon5.png" alt="Icon" />
                  </div>
                  <h4>Complimentary Gym Membership</h4>
                </li>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon6.png" alt="Icon" />
                  </div>
                  <h4>Dedicated Onboarding and Ongoing Support </h4>
                </li>
                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon7.png" alt="Icon" />
                  </div>
                  <h4>Extensive Training and Development Opportunities</h4>
                </li>

                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon8.png" alt="Icon" />
                  </div>
                  <h4>Exciting ICT Opportunities to Work Abroad</h4>
                </li>

                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon9.png" alt="Icon" />
                  </div>
                  <h4>State-of-the-Art Computers &amp; Accessories</h4>
                </li>

                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon10.png" alt="Icon" />
                  </div>
                  <h4>Engaging Company Events &amp; Perks</h4>
                </li>

                <li>
                  <div class="We_hrns_usp_icon">
                    <img src="https://www.sourcedesk.io/public/images/harness_icon11.png" alt="Icon" />
                  </div>
                  <h4>Team Building Retreats and Recognition Awards</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding" style={{ background: '#1b212d url(images/life-at-sdg-love-bg.jpg) no-repeat 50% 50%' }}>
        <div className="container">
          <div className="d-flex justify-content-center align-items-center flex-column flex-sm-row">
            <div className="heart-outer mb-4 mb-sm-0">
              <img src="images/heart.png" alt="" />
              <span className="text-white heart-text">94%</span>
            </div>
            <div className="heart-section-content">
              <p>
                OF EMPLOYEES <br />
                <span>LOVE IT HERE</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding" style={{ background: '#fff' }}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <div className="text_controller">
              <h2 className="text-end pe-sm-3 pe-lg-4 border-right">
                <span>READY TO </span>
                <br />
                JOIN US?
              </h2>
            </div>
            <div className="ps-sm-3 ps-lg-4 me-sm-5 text-center text-sm-start">
              <p>
                Join a team of passionate and dedicated professionals, committed
                <br />
                to collaboration, education, and proactive problem-solving.
              </p>
              <p className="theme_col">Take the first step towards your new career</p>
            </div>
            <NavLink to="/" className="cmn_btn_fill text-nowrap ms-sm-5 me-0">
              Apply Now
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
