import { useEffect } from 'react';

export default function ProgressingDNA() {
  // svg animation js
function useIntersectionObserver () {
  useEffect(() => {
    const inViewport = (entries) => {
      entries.forEach(entry => {
        entry.target.classList.toggle('is-inViewport', entry.isIntersecting);
      });
    };

    const obsOptions = {
      root: null, // Use the viewport as the container
      rootMargin: '0px', // No margin
      threshold: 0.1 // Trigger when 10% of the element is in the viewport
    };

    const observer = new IntersectionObserver(inViewport, obsOptions);

    // Attach observer to every [data-inviewport] element:
    const elementsInViewport = document.querySelectorAll('[data-inviewport]');
    elementsInViewport.forEach(element => {
      observer.observe(element);
    });

    // Cleanup observer on component unmount
    return () => {
      elementsInViewport.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);
};
  return <>
 { useIntersectionObserver()}
  <div className="why_sdg_graphics">
  <svg viewBox="0 0 1000 300">
      <path d="M2,200  Q140,85 146,86  Q165,70   252,138  Q367,225  381,227  Q395,235   519,141  Q586,92   600,87  Q627,67 722,147  Q820,221 842,228 T1000,110" fill="none" />
      <path d="M2,110  Q95,191 150,227  Q163,230   264,155  Q379,78  379,85  Q403,67   560,198  Q615,239   623,225  Q690,178 769,123  Q830,77 850,83 T1000,200" fill="none" />
  </svg>
</div></>
}