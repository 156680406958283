// import React, { useState } from 'react';
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from 'react-router-dom';

function TrustedSlider({defaultTitle,ctaLink = "",ctaTitle = ""}) { // props
  // const [title] = useState(defaultTitle); // use the props value into state
// simple props
// function TrustedSlider(props) {
//   const {title} = props;

const get_trusted_data = "https://www.sourcedesk.io/api/v1/get-trustedby-data";
const [trustedby, setTrustedby] = useState([]);

  var settings = {
      dots: true,
      nav: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7,
      initialSlide: 0,

      appendDots: dots => (
          <div>
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%", 
              backgroundColor: "#c2c2c2",
            }}>
          </div>
        ),

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };

    useEffect(() => {
      getTrusteddata();
    }, []);

    const getTrusteddata = () => {
      axios
        .get(get_trusted_data)
        .then((res) => {
          
          setTrustedby(res.data);

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

if(trustedby.length>0){
  return (
    <>
      <section className="full_width trusted_by hme_trst_slider">
          <div className="container">
              <div className="text_controller text_controller_center">
                  <h2>{defaultTitle}</h2> 
                  <hr />               
              </div>

              <div className="slider-container">
                  <Slider {...settings}>
                  {trustedby.map((item, i) => {
                    return (
                      <div><div className="trstd_sldr_row"><img src={item.logo} alt={item.title} /></div></div>
                    );
                    })}
                      
                      
                  </Slider>
              </div>
              {ctaLink === "" || ctaTitle === "" ? null : <div className="cmn_btn_grp">
                  <NavLink to={ctaLink} className="cmn_btn_fill">{ctaTitle}</NavLink>
              </div>}
              
          </div>
      </section> 
    </>
  )
}
}

export default TrustedSlider;