import { Helmet } from "react-helmet";
import InnerBanner from "../Components/InnerBanner";
import Accordion from 'react-bootstrap/Accordion';

const privacyPolicyData = [{
  title: "Introduction",
  content: [<><p> Welcome to the privacy policy (the "Policy") of Sourcedesk Global Pvt. Ltd. ("we," "us," and/or "our"). This Policy provides information about our software, services, and platforms (collectively, the "Services") to our users ("you," "your"). </p>
    <p> This Privacy Policy explains our practices regarding the collection, use, and disclosure of information, including personally identifiable data ("Personal Data") that we collect through our Services. </p>
    <p> Your access to and use of our Services is subject to our Terms of Service. By using our Services, you agree to the terms of this Privacy Policy and our Terms of Service. </p></>]
},
{
  title: "Information We Collect",
  content: [<><p> When you interact with our Services, we may collect Personal Data and other information from you, as further described below: </p>
    <p> <strong>Personal Data You Provide Through Our Services:</strong> We collect Personal Data from you when you voluntarily provide such information, such as when you create an account, use our Services, contact us for support, or otherwise interact with us. </p>
    <p> <strong>Data from Others:</strong> We may receive Personal Data about you from third parties, such as partners or service providers. </p></>]
},
{
  title: "Applicability",
  content: [<><p>This Privacy Policy applies to:</p>
    <ul>
      <li>
        <p> Users and visitors of our websites, applications, and platforms. </p>
      </li>
      <li>
        <p> Candidates for employment and recipients of our recruitment services. </p>
      </li>
      <li>
        <p> Individuals interested in or engaged in business relationships with Sourcedesk Global. </p>
      </li>
      <li>
        <p> Clients who engage or intend to engage talent/personnel through our services. </p>
      </li>
      <li>
        <p> Talent/Personnel that we source, place on assignments with clients, or to whom we provide services. </p>
      </li>
      <li>
        <p>Our associated third parties, where applicable.</p>
      </li>
      <li>
        <p> Representatives of our business partners, associates, or vendors. </p>
      </li>
    </ul>
    <p> This policy describes the types of personal data we collect, how we use and protect that information, how long we retain it, with whom we share it, cross-border transfers, and the rights individuals can exercise regarding our data practices. Our privacy practices are designed to comply with applicable laws and regulations. </p></>]
},
{
  title: "Policy Scope",
  content: [<><p> This Privacy Policy outlines Sourcedesk Global's practices regarding collecting, using, processing, storing, sharing, and protecting personal data. It details the types of personal information we collect, the purposes for collection, how we handle and safeguard the data, retention periods, disclosures, cross-border transfers, and the privacy rights available to individuals under our data practices and applicable regulations. </p></>]
},
{
  title: "Information We Collect and How We Use It",
  content: [<><p> When you use our services, we may collect and process your information as described below: </p>
    <p> <strong>Registration Information:</strong> If you create an account with us, you may be asked to provide your name, email address, phone number, password, and other information to help verify and secure your account access. </p>
    <p> <strong>Profile Information:</strong> You have the option to provide additional details in your profile, such as your resume/CV, professional background, education, certifications, photo, location, and references. Providing profile information is optional, but can help facilitate better job matching and business opportunities. Use discretion when sharing confidential information publicly. </p>
    <p> <strong>Submitted Information:</strong> We collect personal data that you choose to provide or upload to our platforms, such as when completing surveys, submitting resumes/job applications, or filling out onboarding forms. </p>
    <p> <strong>Customer Support Information:</strong> When you contact our customer support, we collect and store the information you provide, including your name, email, and details about your inquiry or issue. </p>
    <p> <strong>Notifications:</strong> With your consent, we may send push notifications to your mobile device to provide updates and relevant messages. You can manage your notification preferences through your account settings. </p>
    <p> <strong>Technical and Usage Data:</strong> We collect technical information about the devices and systems you use to access our services, such as IP addresses, device identifiers, system versions, and usage statistics. This helps us analyze service usage and optimize performance. </p>
    <p> <strong>Location Data:</strong> With your permission, we may collect and use your device's approximate location data, such as GPS coordinates or location inferred from your IP address. This data helps us improve and personalize our services. Location data collection can be disabled through your device settings. </p>
    <p> <strong>Communications:</strong> If you participate in communications through our platforms that allow sharing with other users, we may collect, record, and store those communications to protect user safety and troubleshoot issues. </p>
    <p> <strong>Other Sources:</strong> We may collect information from external analytics tools like Google Analytics to better understand feature usage and track conversions, ad performance, demographics, and interests. Users can opt out of Google Analytics tracking through the Google Analytics opt-out browser add-on. We do not condition service access on providing more personal data than is reasonably necessary. </p></>]
},
{
  title: "How We Use Your Information",
  content: [<> <h3>To Provide Our Services and Respond to Requests</h3>
    <p> We use the personal data you provide in a manner consistent with this Privacy Policy. If you share personal data for a specific purpose, we use that data to fulfill your request. </p>
    <p> For example, if you contact us with an inquiry, we will use the information you provide to respond and resolve your issue. If you create an account or provide data to access our services, we use that data to provide the services, maintain your account, communicate with you about updates or changes, and monitor service usage. This is necessary for individuals in the EU to respond to your requests and fulfill our contractual obligations. </p>
    <h3>For Marketing Purposes</h3>
    <p> We may use your contact information to send you marketing communications about our services, events, or promotions that may interest you. Each marketing message will contain opt-out instructions if you wish to stop receiving future marketing from us. Please note that you cannot opt out of administrative messages that are reasonably necessary to provide our services. </p>
    <p> Where required by law, we will only send marketing emails if you consent to receive them when providing your data. You can withdraw your marketing consent anytime by following the opt-out instructions or contacting us. </p>
    <h3>For Our Legitimate Business Interests</h3>
    <p> We may use your personal data for the following legitimate business purposes: </p>
    <ul>
      <li>
        <p> Sending administrative information about our services, terms, policies, et cetera </p>
      </li>
      <li>
        <p> Responding to inquiries and fulfilling requests for materials or newsletters </p>
      </li>
      <li>
        <p> Conducting analytics on service usage to improve, benchmark, personalize and market our offerings </p>
      </li>
      <li>
        <p> Supplementing and analyzing our data records using third-party information </p>
      </li>
      <li>
        <p> Preventing fraud, criminal activity, misuse of our systems and ensuring security </p>
      </li>
      <li>
        <p> Complying with legal obligations, responding to authorities, enforcing terms and protecting rights </p>
      </li>
      <li>
        <p> Retaining basic data to comply with deletion requests and prevent unwanted processing </p>
      </li>
    </ul>
    <h3>Recording Participation</h3>
    <p> Recordings may be made of your participation in certain activities (e.g. assessments, challenges), which are considered personal data under this policy. Purposes for such recordings include: </p>
    <ul>
      <li>
        <p>Ensuring the integrity of assessment processes.</p>
      </li>
      <li>
        <p>Providing performance feedback.</p>
      </li>
      <li>
        <p>Facilitating matching with job opportunities.</p>
      </li>
    </ul>
    <p> These recordings are handled per this Privacy Policy and imply your consent under our applicable terms/policies. You may request the deletion of recordings, which could impact job-matching abilities. </p></>]
},
{
  title: "How We Process and Protect Personal Information",
  content: [<><h3>Data Processing and Retention</h3>
    <p> We process the personal data we collect, including through automated means, for the purposes outlined in this policy. We retain personal data only for as long as necessary to fulfill those purposes in accordance with our internal data retention policies and schedules. This helps ensure we do not keep personal information longer than required. </p>
    <h3>Data Security Safeguards</h3>
    <p> We maintain reasonable administrative, technical, and physical security measures designed to protect the personal information you provide against accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. </p>
    <h3>Automatic Data Collection and Use</h3>
    <p> When you access or interact with our services, we and our third-party partners may automatically collect certain information, such as your browser/operating system, referring URLs, search terms, pages visited, links clicked, content viewed, download errors, and other usage data. We may combine this automatically collected log data with other information we have about you. This data helps us improve our services, functionality, marketing, and analytics. </p>
    <h3>Cookies and Tracking Technologies</h3>
    <p> Like most websites, we and our marketing, analytics, and service partners use cookies and similar technologies to collect information during your interactions with our services. Cookies are small data files transferred to your device to enable functionality and record-keeping. We use both persistent cookies and session cookies. You can control cookie usage through your browser settings, but disabling cookies may limit certain service features and functionality. </p>
    <h3>User Feedback</h3>
    <p> We may collect feedback you provide about your experience with other users of our services. Please note that any feedback shared through our platforms may be publicly viewable. </p>
    <h3>Data Retention Practices</h3>
    <p> We will retain your personal information only for as long as reasonably necessary to fulfill the purposes for which it was collected, in addition to any legal or business record-keeping requirements. We cease retention when the original purpose for collection no longer exists. </p></>]
},
{
  title: "Who We May Share Your Personal Data With",
  content: [<><p> We do not disclose the personal data we collect about you except as described in this Privacy Policy or other specific notices. We may share personal data with trusted vendors and service providers that perform services on our behalf under contractual restrictions on unauthorized use or disclosure. We may also disclose your personal data in the following circumstances: </p>
    <ul>
      <li>
        <p> If required by law or legal process to comply with authorities or governmental disclosure requests </p>
      </li>
      <li>
        <p> To law enforcement or others when we believe disclosure is necessary to prevent physical harm, financial loss, or in connection with suspected illegal activity investigations </p>
      </li>
      <li>
        <p> In the event of a corporate transaction, restructuring, merger, acquisition, bankruptcy, or transfer of company assets, we reserve the right to transfer personal data as part of the business assets. </p>
      </li>
    </ul>
    <p> We may transfer personal data between our affiliated group companies, data centers, and our clients for the purposes outlined in this policy. Your data may be stored in databases controlled by our administrative staff, who can access them electronically. </p>
    <p> In any circumstance where we share or transfer personal data, we take measures to protect data privacy and limit use only for the purposes for which it was disclosed. </p></>]
},
{
  title: "Your Data Subject Rights",
  content: [<><p> Depending on the jurisdiction, you may have certain rights relating to the personal data we hold about you under applicable data protection laws. These may include the rights to: </p>
    <ul>
      <li>
        <p> Request access to your personal data and information about how we process it. </p>
      </li>
      <li>
        <p> Request rectification of inaccurate or incomplete personal data. </p>
      </li>
      <li>
        <p> Object to or restrict the processing of your personal data in certain circumstances by contacting us. </p>
      </li>
      <li>
        <p> If your personal data is inaccurate, you can request that we correct it. Where possible, we will share those corrections with others with whom we have shared your data. </p>
      </li>
      <li>
        <p> Request the erasure of your personal data. We will endeavour to delete your data and notify any parties with whom we have previously shared it, where possible. </p>
      </li>
      <li>
        <p> Withdraw any consent previously provided for data processing activities. </p>
      </li>
      <li>
        <p> Opt out of marketing communications and information sharing for marketing purposes. </p>
      </li>
      <li>
        <p> Upon notice of consent withdrawal, we will inform you of the consequences and cease processing your data unless required or permitted by law. </p>
      </li>
      <li>
        <p> Access your personal data to verify lawful processing activities. </p>
      </li>
    </ul>
    <p> We are committed to honouring user rights over their personal information as required under applicable data protection regulations. To exercise any of these rights, please contact us using the information provided below. We may need to verify your identity before processing certain requests. </p>
    <p> Please note that these rights are not absolute and are subject to our rights and obligations under local data protection laws. If you are unsatisfied with our response, you may also have the right to lodge a complaint with your local supervisory authority. </p></>]
},
{
  title: "Non-identifiable and Aggregated Data",
  content: [<><p> When you interact with our services, we may receive and store certain non-identifiable information that cannot be used to identify you /directly. This information is typically collected automatically using various technologies. </p>
    <p> We may store and process this non-identifiable data ourselves, or it may be included in databases owned and maintained by our affiliates, agents, or service providers. We use this data to track and analyze aggregate usage metrics such as total users, service hours, geographic locations, interactions, and trends. </p>
    <p> We may also conduct demographic research and analyze user behaviour in an anonymized, aggregate manner based on the personal data and other information provided to us. This aggregate, de-identified information does not identify you as an individual. </p>
    <p> Sourcedesk Global may share such anonymized, aggregated data with our affiliates, partners, and other parties for lawful business purposes like analyzing industry trends or describing our service offerings. We do not share information that could identify you personally. </p></>]
},
{
  title: "Information We Collect",
  content: [<><p> Whenever we collect your personal data, we aim to provide a link or other notice regarding this Privacy Policy. Additionally, if you contribute work as an individual on projects through our services, we may receive, store, and use details about your usage and activity. We may provide reports containing your personal data to authorized project managers overseeing the projects you work on. </p>
            <p> By voluntarily providing personal data to us, you consent to our collection, use, transfer and processing of that data in accordance with this Privacy Policy. If you share personal information, you acknowledge that it may be transferred to Sourcedesk Global's offices, servers, authorized third parties, and other locations where we operate in India and internationally. </p></>]
},
{
  title: "Disclosures of Your Personal Data",
  content: [<><p> Sourcedesk Global does not sell or rent your personal data to third parties. We consider this information to be a core part of our relationship with you. However, there are certain circumstances where we may disclose your personal data to other parties, as described below: </p>
    <h3>Business Transfers</h3>
    <p> In the event of a corporate transaction, such as a sale, merger, reorganization, dissolution or similar event involving our company, personal data may be part of the transferred business assets. </p>
    <h3>Affiliates and Subsidiaries</h3>
    <p> We may share your personal data within our corporate family of affiliates and related companies for purposes consistent with this Privacy Policy. </p>
    <h3>Vendors and Service Providers</h3>
    <p> We may share your personal data with trusted third-party vendors and service providers that we partner with to perform business operations and functions on our behalf such as payment processing, data hosting, marketing, analytics, and more. These partners are only provided with the information needed to perform the specific services they offer us. </p>
    <h3>Legal Requirements and Protection</h3>
    <p> Sourcedesk Global may disclose personal data if required by law, regulation, legal process, or in good faith if we believe disclosure is needed to comply with legal obligations, protect our rights and property, urgently respond to safety concerns, or prevent legal liability. </p></>]
},
{
  title: "Exclusions",
  content: [<><p> This Privacy Policy does not apply to any personal data that Sourcedesk Global collects through means other than our services and platforms described herein. It also does not cover any unsolicited information you provide to us, such as ideas, product suggestions, or other submissions sent to us outside of our normal data collection processes. </p>
            <p> Any unsolicited information provided to Sourcedesk Global will be considered non-confidential, and we reserve the right to use, reproduce, disclose, and distribute such unsolicited submissions without limitation or attribution back to the sender. </p></>]
},
{
  title: "Children's Privacy",
  content: [<><p> Sourcedesk Global's services are intended for general business use and recruitment purposes. We do not knowingly collect personal data from children under the age of 13. If you are under 13 years old, please do not submit any personal information through our services. </p>
            <p> We encourage parents and legal guardians to monitor their children's internet usage and enforce restrictions against providing personal data through our services without parental permission. If you believe a child under 13 has provided personal data to us, please contact us so we can remove that data from our systems. </p></>]
},
{
  title: "Data Security",
  content: [<><p> Sourcedesk Global takes reasonable technical, administrative, and physical measures to protect the personal data you provide through our services from unauthorized loss, misuse, access, disclosure, alteration or destruction. However, no internet, email or electronic transmission is ever completely secure or error-free. Therefore, you should use appropriate caution regarding any sensitive information submitted to us in this manner. </p></>]
},
{
  title: "Changes to Our Privacy Policy",
  content: [<><p> Our services and business practices may evolve over time, which could require updates to this Privacy Policy. Sourcedesk Global reserves the right to modify or amend this policy at any time without prior notice. We encourage you to review this policy periodically for any changes. </p>
            <p> This Privacy Policy was last updated on the date indicated above. Your continued use of our services after any revisions to this policy will constitute acceptance of the new terms. </p></>]
},
{
  title: "Account Security and User Responsibilities",
  content: [<><ul>
    <li>
      <p> <strong>Data Security:</strong> You are solely responsible for maintaining the security and confidentiality of your login credentials and any account information. Do not share this sensitive data with any third parties, as you will be responsible for all activities conducted through your account. </p>
    </li>
    <li>
      <p> <strong>User Verification:</strong> You acknowledge that Sourcedesk Global may use valid means to verify user identities, including collecting IP addresses, log files, and device data and requesting additional documentation as needed to confirm user information. </p>
    </li>
    <li>
      <p> <strong>Legal Disclosure:</strong> We may provide your registration details to proper authorities if formally requested under applicable laws and regulations. </p>
    </li>
    <li>
      <p> <strong>User Information Display:</strong> Your personal data, registration details, and other information will be displayed on your user profile and across our platforms based on your configured privacy preferences, which you can review and adjust at any time. </p>
    </li>
    <li>
      <p> <strong>Unauthorized Access Reporting:</strong> Promptly notify us of any unauthorized use of your account, any data requests from illegitimate sources claiming affiliation with us, or any other unauthorized access to your personal information. </p>
    </li>
    <li>
      <p> <strong>Privacy Policy Updates:</strong> The privacy policy is updated regularly. We may modify this policy at our discretion to adapt to new data protection regulations. Material changes will be posted, and your continued use after updates constitutes acceptance. </p>
    </li>
  </ul></>]
},
{
  title: "No Refund Policy",
  content: [<><p> At Sourcedesk Global, we strive to provide excellent service and ensure client satisfaction. However, it's important to clarify our policy regarding refunds. We do not offer refunds for our services under any circumstances once payment has been made. </p>
    <h3>Why No Refunds?</h3>
    <p> Our services are designed to deliver immediate, tangible value. The nature of our work involves allocating resources and efforts upfront, making it impractical to reverse a transaction after completion. </p>
    <p> Maintaining a no-refund policy allows us to invest in continuous service improvements and innovations while keeping our offerings affordable without compromising quality. </p>
    <h3>Transparency</h3>
    <p> We believe in transparent communication and are committed to clearly outlining our policies. We encourage clients to reach out to our support team with any questions or concerns before making a payment. This ensures you can make an informed decision. By proceeding with payment, you acknowledge and agree to our no-refund policy. </p></>]
},
{
  title: "Limitations of Liability",
  content: [<><p> We, along with our directors, employees, and agents, shall not be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including but not limited to lost profit, lost revenue, loss of data, or other damages arising from your use of the Site, even if we have been advised of the possibility of such damages. </p></>]
},
{
  title: "No Waiver",
  content: [<><p> Failure or delay in exercising any right, power, or privilege under this Undertaking shall not constitute a waiver thereof. Nor shall any single or partial exercise thereof prevent any other or further exercise thereof or the exercise of any other right, power, or privilege under this Undertaking. </p></>]
},
{
  title: "Remedies",
  content: [<><p> You acknowledge that monetary damages would not suffice for any breach of this Undertaking and agree that we may seek specific performance and injunctive relief for such a breach. These remedies are not exclusive but are in addition to all other remedies available at law or in equity. </p></>]
},
{
  title: "Indemnification",
  content: [<><p> You agree to indemnify and hold us, our affiliates, and our respective directors, officers, employees, agents, and representatives harmless from any losses, damages, costs, and expenses, including reasonable attorneys’ fees and expenses, arising from your breach of this Undertaking or any related actions, suits, proceedings, claims, demands, or judgments. </p></>]
},
{
  title: "No Licenses",
  content: [<><p> We do not grant any licenses, whether implied or otherwise, under any patent, copyright, trademark, trade secret, or other rights by disclosing Confidential Information to you. </p></>]
},
{
  title: "Dispute Resolution",
  content: [<><p> The Parties agree to mediate any disputes or claims in good faith. In the event that mediation fails, any claim shall be settled by arbitration under the Indian Arbitration and Conciliation Act, 1996, with hearings held in Ahmedabad and conducted in English. </p></>]
},
{
  title: "Governing Law",
  content: [<><p> This Undertaking shall be governed by and construed in accordance with the laws of India. The State of Gujarat consents to the exclusive jurisdiction of the state courts located there for any disputes arising from this Undertaking. </p></>]
},
{
  title: "Changes to this Policy",
  content: [<> <p> This Policy may be updated from time to time without notice. We recommend checking for updates periodically. Your continued use of the website or platform after any changes indicates your agreement with the revised Policy without prejudice to your rights under applicable law. </p></>]
},]
export default function PrivacyPolicy(){
  return <>
  <Helmet>
  <title>Dedicated Hiring Resource - Privacy Policy</title>

{/* <!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 
  </Helmet>
  <InnerBanner
  InnBanner="url('images/prvcy_plocy_banner.png')"
  bgRepeat="no-repeat"
  bgColor="#fafbfd"
  BnrRightImg="images/prvcy_plocy_grphcs1.png"
  TitleInn={[<span>Privacy Policy | <br /> Your Information, Your Rights</span>]}
  ParaInn="Last Updated: May, 2024"
  priCTALink="#privacy-policy" priCTATitle="Read More" secCTALink={""} secCTATitle={""} priLinkInternal={true}
/>
<section class="full_width privacy_accordian fix_padding" id="privacy-policy">
  <div class="container">
  <Accordion defaultActiveKey={0}>
    {privacyPolicyData.map((el,i)=><Accordion.Item eventKey={i}>
        <Accordion.Header>{el.title}</Accordion.Header>
        <Accordion.Body>
        {el.content}
        </Accordion.Body>
      </Accordion.Item>)}
  </Accordion>
  </div>
</section>
  </>
}