import { NavLink } from "react-router-dom";
import DevProfiles from "../Components/DevProfiles";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";

export default function Developers() {
  return <>
  <title>Remote Pre-Vetted Developers Available for All Time Zones | Sourcedesk</title>
    <meta name="title" content="Remote Pre-Vetted Developers Available for All Time Zones | Sourcedesk" />
    <meta name="description" content="Discover top-tier remote developers ready for any timezone. Sourcedesk brings you pre-vetted talent for seamless collaboration and global success." />
    {/* <!-- Canonical & hrefLang --> */}
    <link rel="canonical" href="https://www.sourcedesk.io/developers" />
    <link rel="alternate" hrefLang="x-default" href="https://www.sourcedesk.io/developers" />
    <link rel="alternate" hrefLang="en-US" href="https://www.sourcedesk.io/developers" />

    {/* <!-- Twitter Card meta tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@sourcedeskgl" />
    <meta name="twitter:title" content="Remote Pre-Vetted Developers Available for All Time Zones | Sourcedesk" />
    <meta name="twitter:description" content="Discover top-tier remote developers ready for any timezone. Sourcedesk brings you pre-vetted talent for seamless collaboration and global success." />
    <meta name="twitter:image" content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg" />
    {/* <!-- Facebook Open Graph tags --> */}
    <meta property="og:url" content="https://www.sourcedesk.io/developers" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Remote Pre-Vetted Developers Available for All Time Zones | Sourcedesk" />
    <meta property="og:description" content="Discover top-tier remote developers ready for any timezone. Sourcedesk brings you pre-vetted talent for seamless collaboration and global success." />
    <meta property="og:image" content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg" />
    {/* <!-- Additional Open Graph tags for better customization --> */}
    <meta property="og:image:width" content="2400" />
    <meta property="og:image:height" content="1260" />
    <meta property="og:image:alt" content="SourceDesk Global" />
    <meta property="og:locale" content="en_US" />
    
{/* <!-- Head -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" /> */}

{/* <!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 


{/* <!-- Head End --> */}
<script type="application/ld+json">
{`{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "@id": "https://www.sourcedesk.io/#breadcrumb",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Hire Our Genius Minds",
      "item": "javascript:void(0)"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Developers",
      "item": "https://www.sourcedesk.io/developers"
    }
  ]
}`}
</script>
<script type="application/ld+json">
{`{
  "@context": "http://schema.org",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "AI/ML Development",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    }
  },
  "author": {
    "@type": "Person",
    "name": "SourceDesk"
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4.5",
    "bestRating": "5",
    "worstRating": "1"
  },
  "reviewBody": "Review Body"
}`}
</script>
    <InnerBanner
        InnBanner="url('images/banner-developers.jpg')"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/Cube_gr2.png"
        TitleInn={["Accelerate Your Success ",<span>Harness the Power of Our Top Developers</span>]}
        ParaInn="Unlock Exceptional Tech Skills and Collaborate with SDG's Remarkable Developers."
        priCTALink="/schedule-a-call" priCTATitle="Tap Into Our Talent Pool" secCTALink={""} secCTATitle={""}
      />
    <TrustedSlider defaultTitle={"Trusted By"} />
    <section className="full_width devloper_vdo_slider fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Meet Our Exceptional Developers<br /> <span>Unveiling Talent and Expertise</span></h2>  
              <hr />               
          </div>
          <div className="vettng_video" style={{background: "url(images/vttng_prcs_bg.png)", backgroundPosition: "50% 50%", backgroundSize: "cover"}}>
              <NavLink to="/"><div className="vdo_btn"><img src="images/vdo_triangle.png" alt="Icon" /></div></NavLink>
              <h2>Video Title Here</h2>
          </div>
      </div>
    </section>
    <section className="full_width vettng_video_black">
        <div className="container">
            <h2><span>Ignite Innovation</span> Embark on a Journey with Our Top Talent</h2>
            <div className="cmn_btn_grp ">
                <NavLink to="/schedule-a-call" className="cmn_btn_fill">Hire Developers</NavLink>
                <NavLink to="/apply-as-talent" className="cmn_btn_stroke">Apply Here</NavLink>
            </div>
        </div>
    </section>
    <DevProfiles varientClass={"devlopers_profile"} heading={["Meet ",<span>Profoundly-Skilled Developers</span>, " Across Diverse Domain"]} priCTALink={"/schedule-a-call"} priCTATitle={"Hire Now"} />
    <section className="full_width Hiring_Genius remote_dvloper developers_main fix_padding">
    <div className="container">
        <div className="text_controller text_controller_center">
            <h2>Hiring Made Easy <span> How to Hire Top Remote Developers</span></h2>              
            <hr />
        </div>
        <div className="row">
            <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_left">
                <ul>
                    <li> 
                        <h2>Specify Your Skill Requirements</h2>
                        <p>Share your desired skillset and we will promptly schedule a call to thoroughly comprehend your unique needs.</p>
                    </li>
                    <li> 
                        <h2>Unleashing the Power of Our Algorithm and HR Experts</h2>
                        <p>Our advanced algorithm and HR experts quickly provide curated, pre-vetted candidates on your personalized dashboard.</p>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-6 col-sm-4 col-xl-4 col-lg-4  Hrng_Gns_filter"><img src="https://www.sourcedesk.io/public/images/four_dice.png" alt="Hire Top Remote Developers" /> </div>
            <div className="col-12 col-md-6 col-sm-6 col-xl-4 col-lg-6 Hrng_Gns_right">
                <ul>
                    <li> 
                        <h2>Seamless Interview Scheduling</h2>
                        <p>Our Account Manager streamlines interview scheduling, enabling direct candidate connections for project assessment.</p>
                    </li>
                    <li> 
                        <h2>Embark on a Risk-Free Trial</h2>
                        <p>Start confidently with our risk-free two-week trial to experience our developers' capabilities before committing to a long-term engagement. </p>
                    </li>                                                          
                </ul>
            </div>
        </div>
        <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">Hire Developers</NavLink>
            <NavLink to="/hire-development-team" className="cmn_btn_stroke">Hire a Team</NavLink>
        </div>
    </div>
</section>
<section className="full_width sdg_devlopers three_tab fix_padding">
    <div className="container">
        <div className="three_tab_top text_controller">
            <h2>Hire Sourcedesk Global <span> Developers</span></h2>  
            <hr />
        </div>
        <div className="sdg_devlopers_technologies">
            <div>
                <h2>Hire By Tech Skills</h2>
                <ul>
                    <li><img src="images/react.png" alt="React JS" /></li>
                    <li><img src="images/wd_nodejs.png" alt="Node Js" /></li>
                    <li><img src="images/wd_python.png" alt="Python" /></li>
                    <li><img src="images/aws.png" alt="AWS" /></li>
                    <li><img src="images/javascript.png" alt="Javascript" /></li>
                    <li><img src="images/react_native.png" alt="React Native" /></li>
                    <li><img src="images/PostgreSQL.png" alt="PostgreSQL" /></li>
                    <li><img src="images/ruby_on_rails.png" alt="Ruby On Rails" /></li>
                    <li><img src="images/wd_java.png" alt="Java" /></li>
                    <li><img src="images/wd_angular.png" alt="Angular" /></li>
                    <li><img src="images/golang.png" alt="Golang" /></li>
                    <li><img src="images/php.png" alt="PHP" /></li>
                    <li><img src="images/machine_learning.png" alt="Machine Learning" /></li>
                    <li><img src="images/android.png" alt="Android" /></li>
                    <li><img src="images/django.png" alt="Django" /></li>
                    <li><img src="images/ios.png" alt="IOS" /></li>
                    <li><img src="images/wd_laravel.png" alt="Laravel" /></li>
                    <li><img src="images/magento.png" alt="Magento" /></li>
                    <li><img src="images/wd_aspnet.png" alt="ASP.NET" /></li>
                    <li><img src="images/wd_chash.png" alt="C#" /></li>
                    <li><img src="images/react_node.png" alt="React Node" /></li>
                </ul>
                <NavLink to="/see-all-skills">+See all skills</NavLink>
            </div>
            <div>
                <h2> Hire By Roles</h2>
                <ul>
                    <li><img src="images/Full-stack-Development.png" alt="Full-stack Developer" /></li>
                    <li><img src="images/Back-end-Development.png" alt="Back-end Developer" /></li>
                    <li><img src="images/Front-end-Development.png" alt="Front-end Developer" /></li>
                    <li><img src="images/mobile_developement.png" alt="Mobile Developer" /></li>
                    <li><img src="images/App-Developer.png" alt="App Developer" /></li>
                </ul>
                <NavLink to="/see-all-skills">+See all skills</NavLink>
            </div>
        </div>

            
    </div>    
</section>
  </>
}