import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function JobDescription () {
  return <>
  <Helmet>
  <title>Hire Developers at Sourcedesk: Building Excellence in Technology</title>
<meta name="description" content="Discover excellence in technology with Sourcedesk. Hire developers for top-tier solutions. Elevate your projects with skilled professionals. Explore now." />
{/* <!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 

{/* <!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://www.sourcedesk.io/public/css/vanilla-calendar.min.css">
<!-- <link rel="stylesheet" href="https://www.sourcedesk.io/public/css/dark.min.css"> -->
<link rel="stylesheet" href="https://www.sourcedesk.io/public/css/style.css">
<link rel="stylesheet" href="https://www.sourcedesk.io/public/css/responsive.css">


<!-- Head End --> */}
  </Helmet>
  <section className="full_width Home_banner apply_talent_banner inner_banner blog_bnr job_descrpt_bnr">
      <div className="Home_banner_sub" style={{background: "#fafbfd url(images/job_dscrpt_bnr.png) no-repeat 50% 50%"}}>
          <div className="container">
              <div className="row">
                  <div className="col-12Hom_bnr_left inner_bnr_left">
                      <div className="banner_text">
                          <h1>Create Powerful Job Descriptions <br /> Easily with Our Templates</h1>
                      </div>
                      <form>
                          <div className="cmn_btn_grp"> <input type="email" placeholder="Search by Skill/Role" className="cmn_btn_fill" /> <input type="submit" value="Hire Developers" className="cmn_btn_stroke" /> </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section className="full_width hire_skill fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Elevate Your Hiring Process with Our <span> Job Description Templates</span></h2>
              <hr />
          </div>
          <div className="hire_skill_main_panel">
              <div className="home_talent_tab">
                  <ul>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_technical_support_engineer.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zsh.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zoho.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zurb_foundation.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zoom_api.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_ztemplates.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zendesk.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zk.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zoom.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zope2.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_youtube_api.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zend.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zen_cart.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_ze_framework.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zapier.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yii.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_zabbix.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yii2.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yesod.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_xunit.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yelp_api.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yaml.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yahoostore.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_yahoo_software.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_xna.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_xslt.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_xsd.png" alt="Icon" /></NavLink></li>
                      <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hd_xquery.png" alt="Icon" /></NavLink></li>
                  </ul>
              </div>
          </div>
      </div>
  </section>
  <section className="full_width hire_skill foot_gap hire_rolls fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Based on <span>Role</span></h2>
              <hr />
          </div>
          <div className="hire_skill_main_panel_roles">
              <ul>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Microsoft BizTalk Server Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_ml_engineers.png" alt="Icon" /> ML Engineers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Full-Stack .Net Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_mobile_developers.png" alt="Icon" /> Mobile Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_dedicated_web_developers.png" alt="Icon" /> Dedicated Web Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> DAO Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Full-Stack PHP Developer</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Cloud Infrastructure Architect</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_fullstack_java.png" alt="Icon" /> Full-stack Java Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Performance Testing Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_data_scientists.png" alt="Icon" /> Data Scientists</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_data_engineer.png" alt="Icon" /> Data Engineer</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_offshore_laravel_developers.png" alt="Icon" /> Offshore Laravel Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_mobile_developers.png" alt="Icon" /> Offshore Mobile</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_cloud_engineers.png" alt="Icon" /> Cloud Engineers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Data Scraping Specialists</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_data_engineer.png" alt="Icon" /> Data Analyst</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Core Data Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Cucumber Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> nopCommerce Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_data_engineer.png" alt="Icon" /> Dedicated Mobile App Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_web_designers.png" alt="Icon" /> Web Designers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Computer Scientists</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Web Crawler Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Augmented Reality Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_dedicated_web_developers.png" alt="Icon" /> Web Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Data Modeling Experts</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Computer Vision Engineers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_full-stack_node.js_developers.png" alt="Icon" /> Full-stack Node.js Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> DeFi Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_app_developers.png" alt="Icon" /> App Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Freelance Web Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Internet Security Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> Offshore dedicated Developers</NavLink></li>
                  <li><NavLink to="/" onClick={(e)=>e.preventDefault()}><img src="https://www.sourcedesk.io/public/images/hdr_briefcase.png" alt="Icon" /> UI Developers</NavLink></li>
              </ul>
          </div>
      </div>
  </section>
  <section className="full_width home_pgbk fix_padding">
      <div className="container">
          <div className="text_controller text_controller_center">
              <h2>Uncover the Best in the Industry – Find Top Talent Today</h2>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center"> <NavLink to="/pricing" className="cmn_btn_stroke">Explore Our Rate Card for Tailored Pricing</NavLink> <NavLink to="/schedule-a-call" className="cmn_btn_fill">Connect with Leading Professionals Now</NavLink> </div>
      </div>
  </section>
  </>
}