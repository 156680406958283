import React, { useEffect, useState } from 'react';
import axios from "axios";
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import { Link, NavLink } from "react-router-dom";
export default function BlogsSlider({heading, bgColor,priCTALink = "", priCTATitle = ""}) { 
  const get_blog_data = "https://www.sourcedesk.io/api/v1/get-blog-list";
  const [blogList, setblogList] = useState([]);

    const fromMainJs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        appendDots: (dots) => (
            <div>
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: "#c2c2c2",
              }}
            ></div>
          ),
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            }
          },
          {
            breakpoint: 900,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            arrows: false,
            }
          },
          {
            breakpoint: 750,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
          }
        ]
      }

      useEffect(() => {
        getBloglist();
      }, []);

      const getBloglist = () => {
        axios
          .get(get_blog_data)
          .then((res) => {
            
            setblogList(res.data);
  
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };

    // const settings = { 
    //     dots: true, 
    //     infinite: false, 
    //     speed: 500, 
    //     slidesToShow: 3, 
    //     slidesToScroll: 1, 
    //     arrows: true, 
    // }; 
    if(blogList.length>0){
        return ( 
        <section className="full_width fix_padding" style={{backgroundColor: bgColor}}>{/* leadership_thaught blog_popular */}
            <div className="container">
                <div className="text_controller text_controller_center">
                    <h2>{heading}</h2>
                    <hr />
                </div>
                <div className="slider-container thoughts" id="cmn_slider_btn">{/* className="thoughts" id="cmn_slider_btn" */}
                <Slider {...fromMainJs}>
                {blogList.map((item, i) => {
                    return (
                    <div>
                        <div className="ldrshp_thght_items"> <img src={item.blog_image} alt={item.blog_img_alt} />
                            <h4>{item.blog_date}</h4>
                            <h2> {item.blog_title} </h2>
                            <p> {item.blog_short_desc}... </p> <Link to={`/blog-details/${item.blog_slug}`} className="cmn_btn_stroke"> Read More </Link>
                        </div>
                    </div>
                    );
                  })}
                  
                </Slider>
                </div>
                </div> {priCTALink !== "" || priCTATitle !== "" ?  <div className="cmn_btn_grp cmn_btn_grp_center"><NavLink to={priCTALink} className="cmn_btn_fill">{priCTATitle}</NavLink></div> : false}
        </section> );
    }
  }