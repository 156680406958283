import React from 'react'
import { NavLink } from 'react-router-dom';

function Potential() {
  return (
    <>
        <section className="full_width We_harness fix_padding border_top" data-inviewport="scale-in">
            <div className="container">
                <div className="text_controller text_controller_center">
                    <h2><span> Unleashing the Potential of Exceptional Minds </span> <br /> Maximizing Output and Optimizing Contribution </h2>  
                    <hr />               
                    <p>Sourcedesk Global helps top-talent unleash their full potential through world-class benefits and support to deliver the best for you.</p>
                </div>

                <div className="row We_harness_bottom">
                    <div className="col-12 col-md-6 col-sm-5 col-xl-5 We_hrns_image">
                        <svg viewBox="0 0 610 610">
                            <circle cx="306" cy="292" r="290" fill="none"/> 
                        </svg>

                        <div className="We_hrns_image_grp">
                            <img src="images/flower1.png" alt="Flower" className="We_hrns_image_main" />                      
                            <img src="images/flower1_wings1.png" alt="Flower" className="wings_left" />                      
                            <img src="images/flower1_wings2.png" alt="Flower" className="wings_right" />             
                        </div>         
                    </div>

                    <div className="col-12 col-md-6 col-sm-12 col-xl-7 We_hrns_usp">
                        <ul>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon1.png" alt="Icon" /></div>
                                <h4>Comprehensive Medical Benefits</h4>
                            </li>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon2.png" alt="Icon" /></div>
                                <h4>Performance and Review Bonuses</h4>
                            </li>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon3.png" alt="Icon" /></div>
                                <h4>Access to Prestigious Co-Working Spaces, <br /> Daily Meals, and Additional Perks</h4>
                            </li>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon4.png" alt="Icon" /></div>
                                <h4>Gym Membership</h4>
                            </li>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon5.png" alt="Icon" /></div>
                                <h4>Onboarding and Continuous Support Throughout the Engagement</h4>
                            </li>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon6.png" alt="Icon" /></div>
                                <h4>Training and Development Programs for Upskilling</h4>
                            </li>
                            <li>
                                <div className="We_hrns_usp_icon"><img src="images/harness_icon7.png" alt="Icon" /></div>
                                <h4>Opportunities for Working Abroad in the ICT Industry</h4>
                            </li>
                        </ul>
                        <div className="cmn_btn_grp"><NavLink to="/apply-as-talent" className="cmn_btn_fill">Apply For Jobs</NavLink></div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Potential;